/* ---------------------------------- */
/* Freelancers - Grid Layout
------------------------------------- */
.freelancers-container {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 30px);
}

.freelancer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 30px 30px 0;
  width: calc(100% * (1 / 2) - 30px);
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  transition: 0.3s;
  position: relative;
  cursor: default;
  /* height: auto; */
}

.freelancer:hover {
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.14);
  transform: translateY(-4px);
}

.freelancer .bookmark-icon {
  cursor: pointer;
}

.freelancer.tile {
  cursor: pointer;
}

.freelancer-overview {
  text-align: center;
  padding: 38px 20px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.freelancer-overview .freelancer-verified {
  padding-left: 150px;
  margin-top: 10px;  
}

.verified-tag {
  border-radius: 16px;
  border: 1px solid #1db100;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
 }

.verified-tag.pro-delivered {
  border: 1px solid var(--mh-green);
}

.freelancer-overview .freelancer-verified span.basic {
  font-size: 12px;
  white-space: nowrap;
  border: 1px solid #1db100;
  padding: 0px 10px 0px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
}
.freelancer-overview .freelancer-verified span {
  padding: 0px 20px 0px 40px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
}
.freelancer-overview .freelancer-verified span i {
  height: 100%;
  left: 0;
  top: 0;
  padding: 2px 4px 0 4px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 15px;
  text-align: center;
  margin: auto;
}

.freelancer-overview .freelancer-verified span:last-of-type {
  margin-left: 15px;
  border-radius: 16px;
}
.freelancer-overview .freelancer-verified span:last-of-type i {
  font-size: 8px;
  padding-left: 10px;
  top: -2px;
}

.freelancer-overview-inner {
  flex: 1;
}
.profile-image {
  background: #fff;
  border-radius: 50%;
  box-shadow: none;
  overflow: hidden;
  height: 130px;
  width: 130px;
  margin: auto;
}

.freelancer-overview .freelancer-avatar img {
  width: 110px;
  height: 110px;

  border-radius: 50%;
  cursor: pointer;
}

.freelancer-overview .freelancer-avatar {
  width: 110px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  align-items: center;
  display: flex;
}

.home-container .freelancer-overview .freelancer-avatar,
.section.niche .freelancer-overview .freelancer-avatar {
    position: relative;
}

.freelancer-overview .freelancer-avatar .verified-badge {
  position: absolute;
  bottom: 0;
  right: 0;
  display: none !important;
}

.freelancer-name {
  margin-top: 19px;
  margin-left: 150px;
    
}

.home-container .freelancer-overview .freelancer-name {
    margin-left: unset;
}


.section.niche .freelancer-name {
    margin-left: 0px;    
}

.freelancer-rating {
  margin-top: 5px;
  margin-bottom: -10px;
}

.freelancer-name h4 {
  display: flex;
}

.expert-card .freelancer-name h4 {
  display: flex;
  justify-content: center;
}

.freelancer-name h4,
.freelancer-name h4 a {
  font-size: 18px;
  font-weight: bolder;
  color: #333;
  cursor: pointer;
}

.freelancer-name h4 a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 360px;
  display: inline-block;
  letter-spacing: 0.5px;
}
.freelancer-name h4.freelancer-category {
  font-size: 16px;

  font-weight: normal;
  color: #5F5F5F;
  display: flex;
  position: relative;
  right: 0px;
  margin: 2px 0 0 0;
}

@media (max-width: 535px) {
  .freelancer-name h4 {
    display: block;
    text-align: center;
  }
  .freelancer-name h4 span {
    display: flex;
    padding: 0;
    text-align: center;
    justify-content: center;
    position: relative;
    right: auto;
  }
}

.freelancer-name img.flag {
  height: 15px;
  border-radius: 3px;
  position: relative;
  top: -1px;
  display: inline-block;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  margin-left: 4px;
  cursor: default;
}

.freelancer-name span {
  color: #525365;
  margin: 5px 0 7px;
  display: block;
  font-weight: 300;
  /* text-align: center; */
}

.freelancer-details {
  padding: 35px;
  background-color: #fafafa;
  flex-grow: 0;
}

.freelancers-grid-layout .freelancer-details {
  border-radius: 0 0 4px 4px;
}

.freelancers-grid-layout .freelancer-details a.button {
  display: block;
  text-align: center;
  width: 100% !important;
  transition: 0.3s;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.1);
}

.freelancer-details a.button:hover {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.2);
}

.freelancer-details-list ul {
  list-style: none;
  padding: 0;
  margin: 0 0 5px 0;
  font-size: 14.7px;
}

.freelancer-details-list ul li {
  display: inline-block;
  margin-right: 25px;
  line-height: 23px;
  color: #808080;
  margin-bottom: 15px;
  font-weight: 400;
}

.freelancer-details-list ul li:last-child {
  margin-right: 0;
}

.freelancer-details-list ul li strong {
  display: block;
  color: #333;
}

.freelancer-details-list ul li strong i {
  position: relative;
  top: 1px;
  margin-right: -1px;
  margin-left: -2px;
}

/* Detail Item */
.freelancer-detail-item {
  display: inline-block;
  margin: 2px 10px 5px 0;
}

.freelancer-detail-item a,
.freelancer-detail-item {
  color: #888;
}

.freelancer-detail-item a:hover {
  color: #66676b;
}

.freelancer-detail-item i {
  position: relative;
  top: 2px;
  margin-right: 3px;
}


/* Media Queiers for Freelancers List */
@media (max-width: 1366px) {
    .freelances-grid-layout .freelancer-overview .freelancer-avatar {
        width: 100px;
    }
}


@media (max-width: 768px) {
    .review-container {
        width: 100%;
    }
    .freelances-grid-layout.freelancers-container {
        width: 100%;
    }
    .freelances-grid-layout .freelancer {
        margin: 0 0 30px 0;
        width: 100%;
    }
    .freelancers-container.freelancers-grid-layout {
        width: 100%;
    }
    .freelancers-container.freelancers-grid-layout .freelancer {
        width: 100%;
        margin-right: 0;
    }
}


/* Full Page Media Queiers for Freelancers List */
@media (max-width: 2560px) {
  .full-page-container .freelancer {
    width: calc(100% * (1 / 4) - 30px);
  }
}

@media (max-width: 1920px) {
  .full-page-container .freelancer {
    width: calc(100% * (1 / 3) - 30px);
  }
}

@media (max-width: 1366px) {
  .full-page-container .freelancer {
    width: calc(100% * (1 / 2) - 30px);
  }
}

@media (max-width: 1099px) {
  .full-page-container .freelancers-container {
    width: 100%;
  }
  .full-page-container .freelancer,
  .service-plans-section-wrapper .freelancer {
    width: 100%;
    margin-right: 0;
  }
}

/* ---------------------------------- */
/* Freelancers - List Layout
------------------------------------- */
.freelancers-container.freelancers-list-layout {
  width: 100%;
}

.freelancers-list-layout .freelancer {
  margin: 0 0 30px 0;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
.margin-top-5-perc {
  margin-top: 5% !important;
}
.w-120px {
  display: inline-block;
  width: 150px !important;
}

.w-170 {
  width: 170px !important;
}
.radio .custom-size {
  font-size: 46px;
  font-weight: bold;
  color: #202020 !important;
}
.freelancers-list-layout .freelancer-overview {
  text-align: left;
  padding: 30px 40px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 0;
  padding-left: 25px;
  position: relative;
}

.freelancers-list-layout .freelancer-overview-inner {
  flex: 1 1;
  display: flex;
  /*align-items: center;*/
}

.freelancers-list-layout .freelancer-details {
  /*padding: 45px 40px 45px 0;*/
  padding: 30px 30px 45px 0;
  background-color: transparent;
  flex: 0 0 300px;
}

.freelancers-list-layout.checkout .freelancer-details {
  flex: 0 0 240px;
}

@media (max-width: 1366px) {
  .freelancers-list-layout .freelancer-details {
    padding: 45px 40px 45px 0;
    flex: 0 0 240px;
  }

  .freelancers-list-layout.checkout .freelancer-details {
      flex: 0 0 180px;
  }

}

.freelancers-list-layout .freelancer-overview .freelancer-avatar {
  width: 115px;
  margin: 0;
}

.freelancers-list-layout .freelancer-overview .freelancer-name {
  text-align: left;
  margin: 0 0 0 150px;
}

.niche .freelancers-list-layout .freelancer-overview .freelancer-name {
    margin: 0 0 0 0px;
}


.freelancers-list-layout .freelancer-details a.button {
  text-align: center;
  min-width: 60%;
  max-width: 100%;
  width: auto !important;
  transition: 0.3s;
  float: right;
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.15);
}

.freelancers-list-layout .freelancer-details a.button:hover {
  box-shadow: 0 4px 12px rgba(102, 103, 107, 0.25);
}

.freelancers-list-layout .bookmark-icon {
  display: none;
}

.freelancers-list-layout .freelancer-details-list ul {
  float: right;
  display: block;
}

.freelancers-list-layout .freelancer-details-list ul li {
  margin-left: 25px;
  margin-right: 0;
  float: right;
}

/* Freelancers Compact List */
.compact-list.freelancers-list-layout .freelancer {
  border-radius: 16px;
  margin-bottom: 20px;
  /* box-shadow: none; */
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
}

.experts-list-container .compact-list.freelancers-list-layout .freelancer {
  overflow: hidden;  
}

.experts-list-container .compact-list.freelancers-list-layout .freelancer .freelancer-name{
  align-items: start;
}



.freelancer-verified .clamp-text {
    margin-top: 5px;
    margin-bottom: 0;
    white-space: pre-wrap;
    max-width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 54px;
}

.freelancer-overview-inner .freelancer-name {
    display: flex;
    flex-direction: column;
}

.compact-list.freelancers-list-layout .freelancer:hover {
  transform: translateY(0);
}

.compact-list.freelancers-list-layout {
  border-radius: 4px;
  /* box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12); */
}

.compact-list.freelancers-list-layout .freelancer:hover:before {
  opacity: 1;
}

.compact-list.freelancers-list-layout .freelancer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  display: block;
  background: #1db100;
  transition: 0.3s;
  opacity: 0;
}

.compact-list.freelancers-list-layout .freelancer:last-of-type {
  overflow: hidden;
}

.compact-list.freelancers-list-layout .freelancer:first-of-type {
  overflow: hidden;
}

.freelancers-list-layout .freelancer-details-list ul {
    float: right;
    display: block;
}

/* Media Queiers for Freelancers List Layout */


@media (max-width: 1366px) {
    .freelancers-list-layout .freelancer-overview .freelancer-avatar {
        width: 110px;
    }
}

@media (max-width: 1240px) {
    .freelancers-list-layout .freelancer {
        flex-direction: column;
    }
  .freelancer-name h4 span {
    position: relative;
  }
  .freelancers-list-layout .freelancer-overview .freelancer-name {
      margin-left: 120px;
  }

  .freelancer-verified .clamp-text {
      max-width: 540px;
  }

  .freelancer-name h4 a {
      max-width: 540px;
  }

  .freelancers-list-layout .freelancer {
    flex-direction: column;
  }
  .freelancers-list-layout .freelancer-details {
    flex: 1;
    padding: 35px 40px;
    background-color: #fafafa;
  }

  .freelancers-list-layout.checkout .freelancer-details {
    flex: 0;
    padding: 15px 40px;
  }
  .freelancers-list-layout .freelancer-details-list ul {
    float: left;
  }

  .freelancers-list-layout .freelancer-details-list ul li {
    margin-left: 0;
    margin-right: 25px;
    float: left;
    font-weight: 400;
  }

  .freelancers-list-layout .freelancer-details a.button {
    float: none;
    width: 100% !important;
  }

  .freelancers-list-layout .freelancer-overview .freelancer-avatar {
    width: 90px;
  }

  .freelancer-overview .freelancer-avatar img {
    width: 90px;
    height: 90px;
  }

  /* Compact Layout */
  .compact-list.freelancers-list-layout .freelancer-details {
    background-color: transparent;
    padding-top: 0;
  }

  .freelancer-overview .freelancer-verified {
      padding-left: 120px;
  }
  .freelancers-list-layout .freelancer-details-list ul {
      float: left;
  }
}


@media (max-width: 1200px) {
    .freelancer-name h4 a {
        max-width: 400px;
    }
}

@media (max-width: 768px) {

    .freelancer-name h4 {
        flex-direction: column;
    }
    .freelancer-name h4 a {
        max-width: 300px;
    }
    .freelancer-name h4 span {
        padding-left: 0;
    }
    .freelancers-list-layout .freelancer-overview .freelancer-name {
        margin-left: 110px;
    }
    .freelancers-list-layout .freelancer-overview,
    .freelancers-list-layout .freelancer-details {
        padding: 24px 24px 30px 24px;
    }

    .freelancers-list-layout .freelancer-overview .freelancer-avatar {
        width: 80px;
    }

    .freelancers-list-layout .star-rating:before {
        display: block;
        float: none;
        text-align: center;
        max-width: 40px;
        margin-bottom: 4px;
    }

    .freelancer-overview .freelancer-avatar img {
        width: 90px;
        height: 80px;
    }
    .freelancer-overview .freelancer-verified {
        padding-left: 110px;
    }
}

@media (max-width: 630px) {
    .freelancer.bank-account {
        width: 100%;
    }
}



@media (max-width: 530px) {
    .freelancer-overview .freelancer-verified {
        padding-left: 0px;
        padding-top: 20px;
    }
}


@media (max-width: 500px) {
    .experts-list-container .compact-list.freelancers-list-layout .freelancer .freelancer-name {
        align-items: center;
    }
    .freelancer-overview .freelancer-overview-inner {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .freelancer-overview .freelancer-avatar {
        width: 110px;
        height: 110px;
        margin: 0 auto;
        position: relative;
    }
    .freelancers-list-layout .freelancer-overview .freelancer-avatar {
        width: 110px;
        height: 110px;
    }
    .freelancer-overview .freelancer-avatar img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        cursor: pointer;
    }
    .freelancers-list-layout .freelancer-overview .freelancer-name {
        margin: 26px 0 0 0;
    }
    .freelancer-overview .freelancer-verified {
        margin: auto;
        align-items: center;
        flex-direction: column;
        padding-right: 0;
        text-align: center;
    }
    .freelancer-overview .freelancer-verified span:last-of-type {
        max-width: 200px;
        margin-left: 0;
        margin-top: 15px;
    }

    .freelancer-details .freelancer-details-list ul {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}

