@import url("bootstrap-grid.css"); /* Default Reset, Responsive Grid*/
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");
/*@import url("icons.css"); /* Default Reset, Responsive Grid*/

/* Manyhands icons*/

.mh-icon {
    font-size: 10px;
}

.mh-icon-ext-link::after {
    content: url("../images/ArrowTopRightIconDarkGrey.png");
}
