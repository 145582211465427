body p {
    font-size: 16px;
    line-height: 27px;
}
/* Content & Sidebar Offsets */
.content-left-offset {
  padding-left: 30px;
}
.content-right-offset {
  padding-right: 30px;
}

@media (max-width: 992px) {
  .content-left-offset {
    padding-left: 15px;
  }
  .content-right-offset {
    padding-right: 15px;
  }
}

/* Font weight */
.font-weight-100 {
  font-weight: 100;
}
.font-weight-200 {
  font-weight: 200;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}

.margin-0, .margin-none {
    margin: 0px !important;
}
.m-auto {
    margin: auto !important;
}
/* Margin Top */
.margin-top-auto {
  margin-top: auto !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-top-1 {
  margin-top: 1px !important;
}
.margin-top-2 {
  margin-top: 2px !important;
}
.margin-top-3 {
  margin-top: 3px !important;
}
.margin-top-4 {
  margin-top: 4px !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-6 {
  margin-top: 6px !important;
}
.margin-top-7 {
  margin-top: 7px !important;
}
.margin-top-8 {
  margin-top: 8px !important;
}
.margin-top-9 {
  margin-top: 9px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-11 {
  margin-top: 11px !important;
}
.margin-top-12 {
  margin-top: 12px !important;
}
.margin-top-13 {
  margin-top: 13px !important;
}
.margin-top-14 {
  margin-top: 14px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-top-16 {
  margin-top: 16px !important;
}
.margin-top-17 {
  margin-top: 17px !important;
}
.margin-top-18 {
  margin-top: 18px !important;
}
.margin-top-19 {
  margin-top: 19px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-top-21 {
  margin-top: 21px !important;
}
.margin-top-22 {
  margin-top: 22px !important;
}
.margin-top-23 {
  margin-top: 23px !important;
}
.margin-top-24 {
  margin-top: 24px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-top-26 {
  margin-top: 26px !important;
}
.margin-top-27 {
  margin-top: 27px !important;
}
.margin-top-28 {
  margin-top: 28px !important;
}
.margin-top-29 {
  margin-top: 29px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.margin-top-31 {
  margin-top: 31px !important;
}
.margin-top-32 {
  margin-top: 32px !important;
}
.margin-top-33 {
  margin-top: 33px !important;
}
.margin-top-34 {
  margin-top: 34px !important;
}
.margin-top-35 {
  margin-top: 35px !important;
}
.margin-top-36 {
  margin-top: 36px !important;
}
.margin-top-37 {
  margin-top: 37px !important;
}
.margin-top-38 {
  margin-top: 38px !important;
}
.margin-top-39 {
  margin-top: 39px !important;
}
.margin-top-40 {
  margin-top: 40px !important;
}
.margin-top-41 {
  margin-top: 41px !important;
}
.margin-top-42 {
  margin-top: 42px !important;
}
.margin-top-43 {
  margin-top: 43px !important;
}
.margin-top-44 {
  margin-top: 44px !important;
}
.margin-top-45 {
  margin-top: 45px !important;
}
.margin-top-46 {
  margin-top: 46px !important;
}
.margin-top-47 {
  margin-top: 47px !important;
}
.margin-top-48 {
  margin-top: 48px !important;
}
.margin-top-49 {
  margin-top: 49px !important;
}
.margin-top-50 {
  margin-top: 50px !important;
}
.margin-top-51 {
  margin-top: 51px !important;
}
.margin-top-52 {
  margin-top: 52px !important;
}
.margin-top-53 {
  margin-top: 53px !important;
}
.margin-top-54 {
  margin-top: 54px !important;
}
.margin-top-55 {
  margin-top: 55px !important;
}
.margin-top-56 {
  margin-top: 56px !important;
}
.margin-top-57 {
  margin-top: 57px !important;
}
.margin-top-58 {
  margin-top: 58px !important;
}
.margin-top-59 {
  margin-top: 59px !important;
}
.margin-top-60 {
  margin-top: 60px !important;
}
.margin-top-61 {
  margin-top: 61px !important;
}
.margin-top-62 {
  margin-top: 62px !important;
}
.margin-top-63 {
  margin-top: 63px !important;
}
.margin-top-64 {
  margin-top: 64px !important;
}
.margin-top-65 {
  margin-top: 65px !important;
}
.margin-top-66 {
  margin-top: 66px !important;
}
.margin-top-67 {
  margin-top: 67px !important;
}
.margin-top-68 {
  margin-top: 68px !important;
}
.margin-top-69 {
  margin-top: 69px !important;
}
.margin-top-70 {
  margin-top: 70px !important;
}
.margin-top-71 {
  margin-top: 71px !important;
}
.margin-top-72 {
  margin-top: 72px !important;
}
.margin-top-73 {
  margin-top: 73px !important;
}
.margin-top-74 {
  margin-top: 74px !important;
}
.margin-top-75 {
  margin-top: 75px !important;
}
.margin-top-76 {
  margin-top: 76px !important;
}
.margin-top-77 {
  margin-top: 77px !important;
}
.margin-top-78 {
  margin-top: 78px !important;
}
.margin-top-79 {
  margin-top: 79px !important;
}
.margin-top-80 {
  margin-top: 80px !important;
}
.margin-top-81 {
  margin-top: 81px !important;
}
.margin-top-82 {
  margin-top: 82px !important;
}
.margin-top-83 {
  margin-top: 83px !important;
}
.margin-top-84 {
  margin-top: 84px !important;
}
.margin-top-85 {
  margin-top: 85px !important;
}
.margin-top-86 {
  margin-top: 86px !important;
}
.margin-top-87 {
  margin-top: 87px !important;
}
.margin-top-88 {
  margin-top: 88px !important;
}
.margin-top-89 {
  margin-top: 89px !important;
}
.margin-top-90 {
  margin-top: 90px !important;
}
.margin-top-91 {
  margin-top: 91px !important;
}
.margin-top-92 {
  margin-top: 92px !important;
}
.margin-top-93 {
  margin-top: 93px !important;
}
.margin-top-94 {
  margin-top: 94px !important;
}
.margin-top-95 {
  margin-top: 95px !important;
}
.margin-top-96 {
  margin-top: 96px !important;
}
.margin-top-97 {
  margin-top: 97px !important;
}
.margin-top-98 {
  margin-top: 98px !important;
}
.margin-top-99 {
  margin-top: 99px !important;
}
.margin-top-100 {
  margin-top: 100px !important;
}
.margin-top-200 {
  margin-top: 200px !important;
}

/* Margin Bottom */
.margin-bottom-auto {
  margin-bottom: auto !important;
}
.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.margin-bottom-1 {
  margin-bottom: 1px !important;
}
.margin-bottom-2 {
  margin-bottom: 2px !important;
}
.margin-bottom-3 {
  margin-bottom: 3px !important;
}
.margin-bottom-4 {
  margin-bottom: 4px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-6 {
  margin-bottom: 6px !important;
}
.margin-bottom-7 {
  margin-bottom: 7px !important;
}
.margin-bottom-8 {
  margin-bottom: 8px !important;
}
.margin-bottom-9 {
  margin-bottom: 9px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-11 {
  margin-bottom: 11px !important;
}
.margin-bottom-12 {
  margin-bottom: 12px !important;
}
.margin-bottom-13 {
  margin-bottom: 13px !important;
}
.margin-bottom-14 {
  margin-bottom: 14px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-bottom-16 {
  margin-bottom: 16px !important;
}
.margin-bottom-17 {
  margin-bottom: 17px !important;
}
.margin-bottom-18 {
  margin-bottom: 18px !important;
}
.margin-bottom-19 {
  margin-bottom: 19px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-21 {
  margin-bottom: 21px !important;
}
.margin-bottom-22 {
  margin-bottom: 22px !important;
}
.margin-bottom-23 {
  margin-bottom: 23px !important;
}
.margin-bottom-24 {
  margin-bottom: 24px !important;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-bottom-26 {
  margin-bottom: 26px !important;
}
.margin-bottom-27 {
  margin-bottom: 27px !important;
}
.margin-bottom-28 {
  margin-bottom: 28px !important;
}
.margin-bottom-29 {
  margin-bottom: 29px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-bottom-31 {
  margin-bottom: 31px !important;
}
.margin-bottom-32 {
  margin-bottom: 32px !important;
}
.margin-bottom-33 {
  margin-bottom: 33px !important;
}
.margin-bottom-34 {
  margin-bottom: 34px !important;
}
.margin-bottom-35 {
  margin-bottom: 35px !important;
}
.margin-bottom-36 {
  margin-bottom: 36px !important;
}
.margin-bottom-37 {
  margin-bottom: 37px !important;
}
.margin-bottom-38 {
  margin-bottom: 38px !important;
}
.margin-bottom-39 {
  margin-bottom: 39px !important;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}
.margin-bottom-41 {
  margin-bottom: 41px !important;
}
.margin-bottom-42 {
  margin-bottom: 42px !important;
}
.margin-bottom-43 {
  margin-bottom: 43px !important;
}
.margin-bottom-44 {
  margin-bottom: 44px !important;
}
.margin-bottom-45 {
  margin-bottom: 45px !important;
}
.margin-bottom-46 {
  margin-bottom: 46px !important;
}
.margin-bottom-47 {
  margin-bottom: 47px !important;
}
.margin-bottom-48 {
  margin-bottom: 48px !important;
}
.margin-bottom-49 {
  margin-bottom: 49px !important;
}
.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-bottom-51 {
  margin-bottom: 51px !important;
}
.margin-bottom-52 {
  margin-bottom: 52px !important;
}
.margin-bottom-53 {
  margin-bottom: 53px !important;
}
.margin-bottom-54 {
  margin-bottom: 54px !important;
}
.margin-bottom-55 {
  margin-bottom: 55px !important;
}
.margin-bottom-56 {
  margin-bottom: 56px !important;
}
.margin-bottom-57 {
  margin-bottom: 57px !important;
}
.margin-bottom-58 {
  margin-bottom: 58px !important;
}
.margin-bottom-59 {
  margin-bottom: 59px !important;
}
.margin-bottom-60 {
  margin-bottom: 60px !important;
}
.margin-bottom-61 {
  margin-bottom: 61px !important;
}
.margin-bottom-62 {
  margin-bottom: 62px !important;
}
.margin-bottom-63 {
  margin-bottom: 63px !important;
}
.margin-bottom-64 {
  margin-bottom: 64px !important;
}
.margin-bottom-65 {
  margin-bottom: 65px !important;
}
.margin-bottom-66 {
  margin-bottom: 66px !important;
}
.margin-bottom-67 {
  margin-bottom: 67px !important;
}
.margin-bottom-68 {
  margin-bottom: 68px !important;
}
.margin-bottom-69 {
  margin-bottom: 69px !important;
}
.margin-bottom-70 {
  margin-bottom: 70px !important;
}
.margin-bottom-71 {
  margin-bottom: 71px !important;
}
.margin-bottom-72 {
  margin-bottom: 72px !important;
}
.margin-bottom-73 {
  margin-bottom: 73px !important;
}
.margin-bottom-74 {
  margin-bottom: 74px !important;
}
.margin-bottom-75 {
  margin-bottom: 75px !important;
}
.margin-bottom-76 {
  margin-bottom: 76px !important;
}
.margin-bottom-77 {
  margin-bottom: 77px !important;
}
.margin-bottom-78 {
  margin-bottom: 78px !important;
}
.margin-bottom-79 {
  margin-bottom: 79px !important;
}
.margin-bottom-80 {
  margin-bottom: 80px !important;
}
.margin-bottom-81 {
  margin-bottom: 81px !important;
}
.margin-bottom-82 {
  margin-bottom: 82px !important;
}
.margin-bottom-83 {
  margin-bottom: 83px !important;
}
.margin-bottom-84 {
  margin-bottom: 84px !important;
}
.margin-bottom-85 {
  margin-bottom: 85px !important;
}
.margin-bottom-86 {
  margin-bottom: 86px !important;
}
.margin-bottom-87 {
  margin-bottom: 87px !important;
}
.margin-bottom-88 {
  margin-bottom: 88px !important;
}
.margin-bottom-89 {
  margin-bottom: 89px !important;
}
.margin-bottom-90 {
  margin-bottom: 90px !important;
}
.margin-bottom-91 {
  margin-bottom: 91px !important;
}
.margin-bottom-92 {
  margin-bottom: 92px !important;
}
.margin-bottom-93 {
  margin-bottom: 93px !important;
}
.margin-bottom-94 {
  margin-bottom: 94px !important;
}
.margin-bottom-95 {
  margin-bottom: 95px !important;
}
.margin-bottom-96 {
  margin-bottom: 96px !important;
}
.margin-bottom-97 {
  margin-bottom: 97px !important;
}
.margin-bottom-98 {
  margin-bottom: 98px !important;
}
.margin-bottom-99 {
  margin-bottom: 99px !important;
}
.margin-bottom-100 {
  margin-bottom: 100px !important;
}
.margin-bottom-140 {
    margin-bottom: 140px !important;
}

/* Margin Left */
.margin-left-0 {
  margin-left: 0 !important;
}
.margin-left-1 {
  margin-left: 1px !important;
}
.margin-left-2 {
  margin-left: 2px !important;
}
.margin-left-3 {
  margin-left: 3px !important;
}
.margin-left-4 {
  margin-left: 4px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-left-6 {
  margin-left: 6px !important;
}
.margin-left-7 {
  margin-left: 7px !important;
}
.margin-left-8 {
  margin-left: 8px !important;
}
.margin-left-9 {
  margin-left: 9px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-15 {
  margin-left: 15px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-left-25 {
  margin-left: 25px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.margin-left-35 {
  margin-left: 35px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}
.margin-left-45 {
  margin-left: 45px !important;
}
.margin-left-50 {
  margin-left: 50px !important;
}
.margin-left-55 {
  margin-left: 55px !important;
}
.margin-left-60 {
  margin-left: 60px !important;
}
.margin-left-65 {
  margin-left: 65px !important;
}
.margin-left-70 {
  margin-left: 70px !important;
}
.margin-left-75 {
  margin-left: 75px !important;
}
.margin-left-80 {
  margin-left: 80px !important;
}
.margin-left-85 {
  margin-left: 85px !important;
}
.margin-left-90 {
  margin-left: 90px !important;
}
.margin-left-95 {
  margin-left: 95px !important;
}
.margin-left-100 {
  margin-left: 100px !important;
}

/* Margin Right */
.margin-right-0 {
  margin-right: 0 !important;
}
.margin-right-1 {
  margin-right: 1px !important;
}
.margin-right-2 {
  margin-right: 2px !important;
}
.margin-right-3 {
  margin-right: 3px !important;
}
.margin-right-4 {
  margin-right: 4px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-right-6 {
  margin-right: 6px !important;
}
.margin-right-7 {
  margin-right: 7px !important;
}
.margin-right-8 {
  margin-right: 8px !important;
}
.margin-right-9 {
  margin-right: 9px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-right-25 {
  margin-right: 25px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-right-35 {
  margin-right: 35px !important;
}
.margin-right-40 {
  margin-right: 40px !important;
}
.margin-right-45 {
  margin-right: 45px !important;
}
.margin-right-50 {
  margin-right: 50px !important;
}
.margin-right-55 {
  margin-right: 55px !important;
}
.margin-right-60 {
  margin-right: 60px !important;
}
.margin-right-65 {
  margin-right: 65px !important;
}
.margin-right-70 {
  margin-right: 70px !important;
}
.margin-right-75 {
  margin-right: 75px !important;
}
.margin-right-80 {
  margin-right: 80px !important;
}
.margin-right-85 {
  margin-right: 85px !important;
}
.margin-right-90 {
  margin-right: 90px !important;
}
.margin-right-95 {
  margin-right: 95px !important;
}
.margin-right-100 {
  margin-right: 100px !important;
}

.consulting-wrapper {
  position: absolute;
  width: 100%;
}
.relative {
  position: relative;
}

.padding-30 {
  padding: 30px;
}
.padding-20 {
    padding: 20px;
}
.padding-10 {
  padding: 10px;
}
.padding-5 {
  padding: 5px;
}
.padding-0 {
  padding: 0px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/* Padding Top */
.padding-top-0 {
  padding-top: 0px !important;
}
.padding-top-1 {
  padding-top: 1px !important;
}
.padding-top-2 {
  padding-top: 2px !important;
}
.padding-top-3 {
  padding-top: 3px !important;
}
.padding-top-4 {
  padding-top: 4px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.padding-top-6 {
  padding-top: 6px !important;
}
.padding-top-7 {
  padding-top: 7px !important;
}
.padding-top-8 {
  padding-top: 8px !important;
}
.padding-top-9 {
  padding-top: 9px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-11 {
  padding-top: 11px !important;
}
.padding-top-12 {
  padding-top: 12px !important;
}
.padding-top-13 {
  padding-top: 13px !important;
}
.padding-top-14 {
  padding-top: 14px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-top-16 {
  padding-top: 16px !important;
}
.padding-top-17 {
  padding-top: 17px !important;
}
.padding-top-18 {
  padding-top: 18px !important;
}
.padding-top-19 {
  padding-top: 19px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.padding-top-21 {
  padding-top: 21px !important;
}
.padding-top-22 {
  padding-top: 22px !important;
}
.padding-top-23 {
  padding-top: 23px !important;
}
.padding-top-24 {
  padding-top: 24px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}
.padding-top-26 {
  padding-top: 26px !important;
}
.padding-top-27 {
  padding-top: 27px !important;
}
.padding-top-28 {
  padding-top: 28px !important;
}
.padding-top-29 {
  padding-top: 29px !important;
}
.padding-top-30 {
  padding-top: 30px !important;
}
.padding-top-31 {
  padding-top: 31px !important;
}
.padding-top-32 {
  padding-top: 32px !important;
}
.padding-top-33 {
  padding-top: 33px !important;
}
.padding-top-34 {
  padding-top: 34px !important;
}
.padding-top-35 {
  padding-top: 35px !important;
}
.padding-top-36 {
  padding-top: 36px !important;
}
.padding-top-37 {
  padding-top: 37px !important;
}
.padding-top-38 {
  padding-top: 38px !important;
}
.padding-top-39 {
  padding-top: 39px !important;
}
.padding-top-40 {
  padding-top: 40px !important;
}
.padding-top-41 {
  padding-top: 41px !important;
}
.padding-top-42 {
  padding-top: 42px !important;
}
.padding-top-43 {
  padding-top: 43px !important;
}
.padding-top-44 {
  padding-top: 44px !important;
}
.padding-top-45 {
  padding-top: 45px !important;
}
.padding-top-46 {
  padding-top: 46px !important;
}
.padding-top-47 {
  padding-top: 47px !important;
}
.padding-top-48 {
  padding-top: 48px !important;
}
.padding-top-49 {
  padding-top: 49px !important;
}
.padding-top-50 {
  padding-top: 50px !important;
}
.padding-top-51 {
  padding-top: 51px !important;
}
.padding-top-52 {
  padding-top: 52px !important;
}
.padding-top-53 {
  padding-top: 53px !important;
}
.padding-top-54 {
  padding-top: 54px !important;
}
.padding-top-55 {
  padding-top: 55px !important;
}
.padding-top-56 {
  padding-top: 56px !important;
}
.padding-top-57 {
  padding-top: 57px !important;
}
.padding-top-58 {
  padding-top: 58px !important;
}
.padding-top-59 {
  padding-top: 59px !important;
}
.padding-top-60 {
  padding-top: 60px !important;
}
.padding-top-61 {
  padding-top: 61px !important;
}
.padding-top-62 {
  padding-top: 62px !important;
}
.padding-top-63 {
  padding-top: 63px !important;
}
.padding-top-64 {
  padding-top: 64px !important;
}
.padding-top-65 {
  padding-top: 65px !important;
}
.padding-top-66 {
  padding-top: 66px !important;
}
.padding-top-67 {
  padding-top: 67px !important;
}
.padding-top-68 {
  padding-top: 68px !important;
}
.padding-top-69 {
  padding-top: 69px !important;
}
.padding-top-70 {
  padding-top: 70px !important;
}
.padding-top-71 {
  padding-top: 71px !important;
}
.padding-top-72 {
  padding-top: 72px !important;
}
.padding-top-73 {
  padding-top: 73px !important;
}
.padding-top-74 {
  padding-top: 74px !important;
}
.padding-top-75 {
  padding-top: 75px !important;
}
.padding-top-76 {
  padding-top: 76px !important;
}
.padding-top-77 {
  padding-top: 77px !important;
}
.padding-top-78 {
  padding-top: 78px !important;
}
.padding-top-79 {
  padding-top: 79px !important;
}
.padding-top-80 {
  padding-top: 80px !important;
}
.padding-top-81 {
  padding-top: 81px !important;
}
.padding-top-82 {
  padding-top: 82px !important;
}
.padding-top-83 {
  padding-top: 83px !important;
}
.padding-top-84 {
  padding-top: 84px !important;
}
.padding-top-85 {
  padding-top: 85px !important;
}
.padding-top-86 {
  padding-top: 86px !important;
}
.padding-top-87 {
  padding-top: 87px !important;
}
.padding-top-88 {
  padding-top: 88px !important;
}
.padding-top-89 {
  padding-top: 89px !important;
}
.padding-top-90 {
  padding-top: 90px !important;
}
.padding-top-91 {
  padding-top: 91px !important;
}
.padding-top-92 {
  padding-top: 92px !important;
}
.padding-top-93 {
  padding-top: 93px !important;
}
.padding-top-94 {
  padding-top: 94px !important;
}
.padding-top-95 {
  padding-top: 95px !important;
}
.padding-top-96 {
  padding-top: 96px !important;
}
.padding-top-97 {
  padding-top: 97px !important;
}
.padding-top-98 {
  padding-top: 98px !important;
}
.padding-top-99 {
  padding-top: 99px !important;
}
.padding-top-100 {
  padding-top: 100px !important;
}

/* Padding Bottom */
.padding-bottom-0 {
  padding-bottom: 0px !important;
}
.padding-bottom-1 {
  padding-bottom: 1px !important;
}
.padding-bottom-2 {
  padding-bottom: 2px !important;
}
.padding-bottom-3 {
  padding-bottom: 3px !important;
}
.padding-bottom-4 {
  padding-bottom: 4px !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.padding-bottom-6 {
  padding-bottom: 6px !important;
}
.padding-bottom-7 {
  padding-bottom: 7px !important;
}
.padding-bottom-8 {
  padding-bottom: 8px !important;
}
.padding-bottom-9 {
  padding-bottom: 9px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-bottom-11 {
  padding-bottom: 11px !important;
}
.padding-bottom-12 {
  padding-bottom: 12px !important;
}
.padding-bottom-13 {
  padding-bottom: 13px !important;
}
.padding-bottom-14 {
  padding-bottom: 14px !important;
}
.padding-bottom-15 {
  padding-bottom: 15px !important;
}
.padding-bottom-16 {
  padding-bottom: 16px !important;
}
.padding-bottom-17 {
  padding-bottom: 17px !important;
}
.padding-bottom-18 {
  padding-bottom: 18px !important;
}
.padding-bottom-19 {
  padding-bottom: 19px !important;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.padding-bottom-21 {
  padding-bottom: 21px !important;
}
.padding-bottom-22 {
  padding-bottom: 22px !important;
}
.padding-bottom-23 {
  padding-bottom: 23px !important;
}
.padding-bottom-24 {
  padding-bottom: 24px !important;
}
.padding-bottom-25 {
  padding-bottom: 25px !important;
}
.padding-bottom-26 {
  padding-bottom: 26px !important;
}
.padding-bottom-27 {
  padding-bottom: 27px !important;
}
.padding-bottom-28 {
  padding-bottom: 28px !important;
}
.padding-bottom-29 {
  padding-bottom: 29px !important;
}
.padding-bottom-30 {
  padding-bottom: 30px !important;
}
.padding-bottom-31 {
  padding-bottom: 31px !important;
}
.padding-bottom-32 {
  padding-bottom: 32px !important;
}
.padding-bottom-33 {
  padding-bottom: 33px !important;
}
.padding-bottom-34 {
  padding-bottom: 34px !important;
}
.padding-bottom-35 {
  padding-bottom: 35px !important;
}
.padding-bottom-36 {
  padding-bottom: 36px !important;
}
.padding-bottom-37 {
  padding-bottom: 37px !important;
}
.padding-bottom-38 {
  padding-bottom: 38px !important;
}
.padding-bottom-39 {
  padding-bottom: 39px !important;
}
.padding-bottom-40 {
  padding-bottom: 40px !important;
}
.padding-bottom-41 {
  padding-bottom: 41px !important;
}
.padding-bottom-42 {
  padding-bottom: 42px !important;
}
.padding-bottom-43 {
  padding-bottom: 43px !important;
}
.padding-bottom-44 {
  padding-bottom: 44px !important;
}
.padding-bottom-45 {
  padding-bottom: 45px !important;
}
.padding-bottom-46 {
  padding-bottom: 46px !important;
}
.padding-bottom-47 {
  padding-bottom: 47px !important;
}
.padding-bottom-48 {
  padding-bottom: 48px !important;
}
.padding-bottom-49 {
  padding-bottom: 49px !important;
}
.padding-bottom-50 {
  padding-bottom: 50px !important;
}
.padding-bottom-51 {
  padding-bottom: 51px !important;
}
.padding-bottom-52 {
  padding-bottom: 52px !important;
}
.padding-bottom-53 {
  padding-bottom: 53px !important;
}
.padding-bottom-54 {
  padding-bottom: 54px !important;
}
.padding-bottom-55 {
  padding-bottom: 55px !important;
}
.padding-bottom-56 {
  padding-bottom: 56px !important;
}
.padding-bottom-57 {
  padding-bottom: 57px !important;
}
.padding-bottom-58 {
  padding-bottom: 58px !important;
}
.padding-bottom-59 {
  padding-bottom: 59px !important;
}
.padding-bottom-60 {
  padding-bottom: 60px !important;
}
.padding-bottom-61 {
  padding-bottom: 61px !important;
}
.padding-bottom-62 {
  padding-bottom: 62px !important;
}
.padding-bottom-63 {
  padding-bottom: 63px !important;
}
.padding-bottom-64 {
  padding-bottom: 64px !important;
}
.padding-bottom-65 {
  padding-bottom: 65px !important;
}
.padding-bottom-66 {
  padding-bottom: 66px !important;
}
.padding-bottom-67 {
  padding-bottom: 67px !important;
}
.padding-bottom-68 {
  padding-bottom: 68px !important;
}
.padding-bottom-69 {
  padding-bottom: 69px !important;
}
.padding-bottom-70 {
  padding-bottom: 70px !important;
}
.padding-bottom-71 {
  padding-bottom: 71px !important;
}
.padding-bottom-72 {
  padding-bottom: 72px !important;
}
.padding-bottom-73 {
  padding-bottom: 73px !important;
}
.padding-bottom-74 {
  padding-bottom: 74px !important;
}
.padding-bottom-75 {
  padding-bottom: 75px !important;
}
.padding-bottom-76 {
  padding-bottom: 76px !important;
}
.padding-bottom-77 {
  padding-bottom: 77px !important;
}
.padding-bottom-78 {
  padding-bottom: 78px !important;
}
.padding-bottom-79 {
  padding-bottom: 79px !important;
}
.padding-bottom-80 {
  padding-bottom: 80px !important;
}
.padding-bottom-81 {
  padding-bottom: 81px !important;
}
.padding-bottom-82 {
  padding-bottom: 82px !important;
}
.padding-bottom-83 {
  padding-bottom: 83px !important;
}
.padding-bottom-84 {
  padding-bottom: 84px !important;
}
.padding-bottom-85 {
  padding-bottom: 85px !important;
}
.padding-bottom-86 {
  padding-bottom: 86px !important;
}
.padding-bottom-87 {
  padding-bottom: 87px !important;
}
.padding-bottom-88 {
  padding-bottom: 88px !important;
}
.padding-bottom-89 {
  padding-bottom: 89px !important;
}
.padding-bottom-90 {
  padding-bottom: 90px !important;
}
.padding-bottom-91 {
  padding-bottom: 91px !important;
}
.padding-bottom-92 {
  padding-bottom: 92px !important;
}
.padding-bottom-93 {
  padding-bottom: 93px !important;
}
.padding-bottom-94 {
  padding-bottom: 94px !important;
}
.padding-bottom-95 {
  padding-bottom: 95px !important;
}
.padding-bottom-96 {
  padding-bottom: 96px !important;
}
.padding-bottom-97 {
  padding-bottom: 97px !important;
}
.padding-bottom-98 {
  padding-bottom: 98px !important;
}
.padding-bottom-99 {
  padding-bottom: 99px !important;
}
.padding-bottom-100 {
  padding-bottom: 100px !important;
}
.padding-bottom-200 {
  padding-bottom: 200px !important;
}

/* Padding Right */
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-15 {
  padding-right: 15px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-right-25 {
  padding-right: 25px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-right-35 {
  padding-right: 35px !important;
}
.padding-right-40 {
  padding-right: 40px !important;
}
.padding-right-45 {
  padding-right: 45px !important;
}
.padding-right-50 {
  padding-right: 50px !important;
}
.padding-right-55 {
  padding-right: 55px !important;
}
.padding-right-55 {
  padding-right: 55px !important;
}
.padding-right-55 {
  padding-right: 55px !important;
}
.padding-right-55 {
  padding-right: 55px !important;
}
.padding-right-60 {
  padding-right: 60px !important;
}
.padding-right-65 {
  padding-right: 65px !important;
}
.padding-right-70 {
  padding-right: 70px !important;
}
.padding-right-75 {
  padding-right: 75px !important;
}
.padding-right-80 {
  padding-right: 80px !important;
}
.padding-right-85 {
  padding-right: 85px !important;
}
.padding-right-90 {
  padding-right: 90px !important;
}
.padding-right-95 {
  padding-right: 95px !important;
}
.padding-right-100 {
  padding-right: 100px !important;
}

/* Padding Left */
.padding-left-0 {
  padding-left: 0 !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.padding-left-15 {
  padding-left: 15px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-25 {
  padding-left: 25px !important;
}
.padding-left-30 {
  padding-left: 30px !important;
}
.padding-left-35 {
  padding-left: 35px !important;
}
.padding-left-40 {
  padding-left: 40px !important;
}
.padding-left-45 {
  padding-left: 45px !important;
}
.padding-left-50 {
  padding-left: 50px !important;
}
.padding-left-55 {
  padding-left: 55px !important;
}
.padding-left-55 {
  padding-left: 55px !important;
}
.padding-left-55 {
  padding-left: 55px !important;
}
.padding-left-55 {
  padding-left: 55px !important;
}
.padding-left-60 {
  padding-left: 60px !important;
}
.padding-left-65 {
  padding-left: 65px !important;
}
.padding-left-70 {
  padding-left: 70px !important;
}
.padding-left-75 {
  padding-left: 75px !important;
}
.padding-left-80 {
  padding-left: 80px !important;
}
.padding-left-85 {
  padding-left: 85px !important;
}
.padding-left-90 {
  padding-left: 90px !important;
}
.padding-left-95 {
  padding-left: 95px !important;
}
.padding-left-100 {
  padding-left: 100px !important;
}

.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.top-0 {
    top: 0;
}
.bottom-0 {
    bottom: 0;
}
.bottom-10 {
  bottom: 10px !important;
}
.bottom-20 {
  bottom: 20px !important;
}
.bottom-30 {
  bottom: 30px !important;
}
.bottom-50 {
  bottom: 50px !important;
}
.bottom-70 {
  bottom: 70px !important;
}
.bottom-80 {
  bottom: 80px !important;
}
.bottom-100 {
  bottom: 100px !important;
}


.border-none {
  border: none !important;
  box-shadow: none !important;
}

.border-0 {
  border: none !important;
}
.no-shadow {
    box-shadow: none !important;
}
.border-with-shadow {
    border: 0.1px solid #00000012;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
}

.rounded-md {
  border-radius: 8px !important;
}


/* ---------------------------------- */
/* Keywords Input
------------------------------------- */
.keywords-container {
  margin-bottom: -15px;
}

.keywords-list {
  display: block;
  float: left;
  height: auto;
}

.keyword {
  background-color: rgba(102, 103, 107, 0.07);
  color: #66676b;
  display: inline-block;
  float: left;
  padding: 0;
  border-radius: 4px;
  cursor: default;
  margin: 0 7px 7px 0;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  animation: KeywordIn 0.3s ease-in-out;
  animation-fill-mode: both;
  transition: 0.4s;
  overflow: hidden;
  max-width: 100%;
  font-size: 14.7px;
}

@keyframes KeywordIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.keyword.keyword-removed {
  max-width: 0;
  margin: 0;
  overflow: hidden;
  opacity: 0;
  animation: KeywordOut 0.4s ease-in-out;
  animation-fill-mode: both;
  pointer-events: none;
}

@keyframes KeywordOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    margin-top: -35px;
  }
}

.keyword-text {
  padding-right: 12px;
}

.keyword .keyword-remove:before {
  display: inline-block;
  cursor: pointer;
  font-family: "Feather-Icons";
  content: url('../images/x.svg');
  line-height: 20px;
  position: relative;
  top: 6px;
  margin-right: 4px;
  margin-left: 5px;
  scale: 0.6;
}

.keyword-input-container {
  position: relative;
}

.keyword-input-container .keyword-input-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 36px;
  padding: 0;
  color: #fff;
  background-color: #66676b;
  border-radius: 4px;
  margin: 6px;
  font-size: 19px;
  text-align: center;
  line-height: 36px;
}

.keyword-input-container .keyword-input-button i {
  color: #fff;
  position: relative;
  top: 2px;
}

input.keyword-input {
  margin: 0 0 15px 0;
}
.input-with-icon {
    display: block;
    position: relative;
}
a,
button {
    outline: none !important;
}
body a:focus,
body a:hover {
    text-decoration: none;
}
.position-relative {
    position: relative !important;
}
.position-absolute {
    position: absolute !important;
}
.position-fixed {
    position: fixed !important;
}
.h-auto {
    height: auto !important;
}
.h-100 {
    height: 100% !important;
}
.w-auto {
    width: auto !important;
}
.w-100 {
    width: 100% !important;
    display: block;
}
.w-75 {
    width: 75% !important;
}
.w-50 {
    width: 50% !important;
}

.text-align-start {
    text-align: start;
}

.text-align-center {
    text-align: center;
}

.d-block {
    display: block !important;
}

.d-flex {
    display: flex !important;
}
.flex-dir-row {
    flex-direction: row !important;
}
.flex-dir-col {
    flex-direction: column !important;
}
.align-items-center {
    align-items: center;
}
.justify-content-between {
    justify-content: space-between;
}
.justify-content-around {
    justify-content: space-around;
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}
.d-inline-flex {
    display: inline-flex !important;
}
.nowrap {
    white-space: nowrap !important;
}
.disable-scrollbar {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.scroll-y {
    overflow-y: scroll;
}


.panel-body.gray.waitlist {
    height: fit-content;
}

@media screen and (max-width: 479px) {
    div.div-block-12.justify-right {
        display: flex;
    }
}
