:root {
    --light-gray: transparent;
    --dark-grey: #2e2e2e;
    --primary-6: #6ef040;
    --primary-2: #1db100;
    --white: white;
    --primary-3: #1db100;
    --primary-4: #105c01;
    --primary-5: #015d4a;
    --gray-3: #c2c2c2;
    --primary-1: #015d4a;
    --gray-4: white;
    --transparent: rgba(255, 255, 255, 0);
    --gray-2: #9e9e9e;
    --gray-5: #f7f7f7;
    --gray-6: #eee;

    /*OLD THEMES*/
    --dark: #242431;
    --dark-secondary: #525365;
    --red: #418502; /* #e60000;*/
    --red-secondary: #e60000;
    --grey: #d8dbe0;
    --grey-secondary: #f5f6f7;
    --grey-dark: #8a8d9c;
    --info-theme: #6ef040;
    --theme-dark: #002019;
    --theme-light: #ebedef;
    --footer-grey: #141827;
    --mh-green: #015d4a;
    --mh-light-green: #e5ffdc;
    --account-type-light: #ffffff;
    --theme-input-light: #c9ccd4;
    --green: #68cf00;
    --green-dark: #418502;
    --green-alt: #509e00;
    --green-fade: #eaffef;
    --grey-black: #08081b;
    --grey-alt: #6d6d76;
    --warning: #f1c40f;
}


* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
textarea,
select {
    color: var(--dark);
}

#header {
    position: sticky;
    top: 0;
    z-index: 999;
    padding: 0;
    font-size: 16px;
    background-color: #fff;
    transition: background 0.3s;
}
#header .container {
    margin-left: 0px;
    margin-right: 0px;
    max-width: 100%;
    padding: 0 20px;
}


body {
  margin: 0;
  background-color: #fff;
  padding-bottom:0;
}

.section.small-bottom-space.smaller-top-space
{
    margin-bottom: 0;
}

body.no-scroll .section.small-bottom-space.smaller-top-space.position-relative {
    z-index: 1001;
}

body.mobile-menu-open {
    overflow:hidden;
}

.mobile-menu-open .w-nav-overlay {
    overflow: visible;
}
.w-nav-overlay [data-nav-menu-open] {
    overflow-y: scroll;
    position: fixed;
}

/* INPUTS */

label,
legend {
    display: block;
    font-weight: bold;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 8px;
}


input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input.with-border,
input[type="text"].with-border,
input[type="password"].with-border,
input[type="email"].with-border,
input[type="number"].with-border,
textarea.with-border,
select.with-border {
/*.multiselect-container input {*/
/*  background: var(--account-type-light);*/
    border: 1px solid var(--theme-input-light);
/*  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);*/
    border-radius: 8px;
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}
input.with-border:focus,
input[type="text"].with-border:focus,
input[type="password"].with-border:focus,
input[type="email"].with-border:focus,
input[type="number"].with-border:focus,
textarea.with-border:focus {
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.08);
}

input[type="submit"] {
  border: none;
  padding: 11px 18px;
  width: auto;
}

input[type="checkbox"] {
  display: inline;
}

input[type="radio"] {
  width: 15px;
  height: 15px;
  cursor: pointer;
  box-shadow: none;
}

input.form-input.form-input-large, div.form-input.form-input-large {
    height: 55px;
}

/* Input Placeholder Color */
::placeholder {
  color: #808080;
  opacity: 1;
}

textarea {
  height: auto;
  line-height: 27px;
  padding: 20px;
  min-height: 130px;
  transition: none !important;
  min-width: 100%;
}


.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
input.has-error, textarea.has-error,
.multiselect-container.has-error,
.image-uploader.has-error {
    border-color: #c54947 !important;
    border-width: 2px !important;

}

input.has-error {
    border-color: #c54947 !important;
    border-width: 2px;
}

.onboard-container-margin .profile-image-container.has-error {
    border: 2px solid  #c54947 !important;
}


/* END INPUTS*/

/* CUSTOM CAROUSEL TRANSITION */
.manyhands-carousel .w-slide:not(:first-of-type) {
    position: absolute;
    left: 0;
    transition: opacity 500ms;
    opacity: 0;
}

.manyhands-carousel .w-slide.fade-out {
    opacity: 1;
    -webkit-animation: fadeOut 500ms linear forwards;
    animation: fadeOut 500ms linear forwards;
    background-image: none;
    object-fit: cover;
}

.manyhands-carousel .w-slide.fade-in {
    opacity: 0;
    -webkit-animation: fadeIn 500ms linear forwards;
    animation: fadeIn 500ms linear forwards;
    background-image: none;
    object-fit: coiver;
}

@-webkit-keyframes fadeIn {
    0%,{ opacity: 0 }
    100% { opacity: 1 }
}
@keyframes fadeIn {
    0%,{ opacity: 0 }
    100% { opacity: 1 }
}
@-webkit-keyframes fadeOut {
    0%,{ opacity: 1 }
    100% { opacity: 0 }
}
@keyframes fadeOut {
    0%,{ opacity: 1 }
    100% { opacity: 0 }
}

/* END CUSTOM CAROUSEL TRANSITION */

/* LOADER */


.loader.loader-small {
    border-width: 6px;
    width: 38px;
    height: 38px;
}

.loader.loader-white {
    border: 8px solid #f3f3f3;
    border-top: 8px solid white;
    border-bottom: 8px solid white;
    border-right: 8px solid white;
}

.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #6ef040;
    border-bottom: 8px solid #6ef040;
    border-right: 8px solid #6ef040;
    width: 60px;
    height: 60px;
    scale: 65%;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* notes */

.note.new {
  background-color: var(--primary-6);
  color:#265c4b
}
.note.paused {
  background-color: var(--warning);
}
.note.started {
  background-color: var(--mh-green);
}
.note.cancelled {
  background-color: var(--red-secondary)
}
.note.small {
  font-size: 10px;
  padding: 1.5px 5px 2px;
  border-radius: 8px;
  margin-left: 5px;
}


/* SERVICE PACK PAGE STYLING */

/* –––––––––––––––––––– SP HERO –––––––––––––––––––– */
.bg-transparent
{
    background-color: transparent !important;
}
.bg-white
{
    background-color: #fff !important;
}
.color-white
{
    color: #fff !important;
}
.bg-gray-5 {
    background-color: var(--gray-5) !important;
}

.bg-gray-6 {
    background-color: var(--gray-6) !important;
}


.bg-light-green
{
    background-color: #f4fff0 !important;
}

.color-dark-secondary {
    color: var(--dark-secondary) !important;
}
.color-dark-grey {
    color: var(--dark-grey) !important;
}

.color-mh-green {
    color: var(--mh-green) !important;
}
.bg-mh-green {
    background-color: var(--mh-green) !important;
}

.color-grey-text {
    color: var(--grey-alt) !important;
}

.text-red {
    color: #c00003;
}

.text-yellow {
    color: var(--warning);
}

.bg-grey-secondary {
    background-color: var(--grey-secondary) !important;
}

.button-large.bg-white:hover {
    background-color: var(--primary-1) !important;
}

.button-large.bg-dark-green {
    color: var(--dark-grey);
    font-size: 19px;
}
.button-large.bg-dark-green.large {
    font-size: 23px;
}
.button-large.bg-dark-green.large.lumo-green {
    background-color: var(--primary-6);
    opacity: 1;
    color: var(--primary-5);
    font-weight: 500;
}
.button-large.bg-dark-green.large.lumo-green:hover {
    background-color: var(--primary-5);
    color: var(--primary-6);
}

.button.button-load-more {
    background-color: var(--dark-grey) !important;
    color: white !important;
    height: 40px;
}

.image.profile-image {
    width: 120px;
}
.profile-image-container {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 30px;
    max-width: 150px;
    flex: 0 0 150px;
    padding: 0;
    border-radius: 8px;
}
.profile-image-container i {
    position: absolute;
    left: 3px;
    bottom: 0;
    color: var(--dark-secondary);
    font-size: 20px;
}
.profile-add {
    width: 140px;
    height: 140px;
    position: relative;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}
.profile-add input {
    cursor: pointer;
}
.profile-add img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
}

.circle.rounded-profile-image {
  width: 54px;
  min-width: 54px;
  height: 54px;
  min-height: 54px;
}
/*overwrite*/

.no-flex-wrap {
    flex-wrap: none;
}

.pricing-list li>div.row {
    flex-wrap: unset;
}

div.live-chat-wrapper---brix {
    display: flex;
    z-index: 1100;
}


.live-chat-wrapper---trigger {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.mobilescreenhero {
    max-width: 1280px;
}

@media screen and (max-width: 479px) {


    .button.button-load-more {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }


    .mobilescreenhero {
        max-width: 100vw;
    }


    .live-chat-bubbble---brix.whatsapp.open {
        display: none;
    }

    div.live-chat-message-bubble---brix.whatsapp {
        bottom: 0;
        margin-bottom: 0;
    }

    .live-chat-wrapper---trigger {
        position: fixed;
        bottom: 0;
        right: 0;
    }

    .live-chat-wrapper---trigger.btm-space {
        bottom: 70px;
    }


    .live-chat-wrapper---trigger.authed {
        bottom: 100px;
    }
}

.slide-box.margin-right.new.dark.automotive
{
    background-image: url("../images/AutoHero.webp");
}
.slide-box.margin-right.new.dark.travel-and-tourism
{
    background-image: url("../images/TravelHero.webp");
}
.slide-box.margin-right.new.dark.food-and-beverage
{
    background-image: url("../images/FoodHero.webp");
}
.slide-box.margin-right.new.dark.professional-services
{
    background-image: url('../images/ServicesHero.webp');
}
.slide-box.margin-right.new.dark.tech-startup
{
    background-image: url('../images/SaaSHero.webp');
}
.slide-box.margin-right.new.dark.construction
{
    opacity: 1;
    background-color: #000;
    background-image: url("../images/HomeRepairHero.webp");
    background-position: 50% 0;
}

#getWaitlistContainer span.gw-text-sm, #getWaitlistContainer div.gw-text-sm {
    color: var(--dark-grey);
    margin-top: 10px;
    font-weight: 600;
    line-height: 18px;
    font-size: 16px;
}

#getWaitlistContainer input.gw-text-sm,
#getWaitlistContainer select.gw-text-sm
{
    height: 60px !important;
    padding: 8px 24px;
    background-color: var(--white);
    color: var(--primary-1);
    border-style: solid;
    border-color: #caccd4;
    outline: none;
    border-radius: 6px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    transition: background-color .2s;
}
#getWaitlistContainer input.gw-text-sm:focus
{
    border-width: 2px;
    border-color: var(--primary-1);
}
#getWaitlistContainer input.gw-text-sm:focus-visible
{
    color: var(--primary-1);
    font-weight: 600;
}

#getWaitlistContainer #getWaitlistInnerContainer>div.gw-mt-4:last-of-type {
    display: none;
}

#getWaitlistContainer button#primaryCTA {
  box-shadow: none;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
  transition-property: none;
  display: block;
  -webkit-appearance: button;
  height: 60px;
  background-color: var(--primary-1);
  color: var(--primary-6);
  text-align: center;
  border-radius: 10px;
  padding-left: 36px;
  padding-right: 36px;
  transition: box-shadow .2s;
  padding-bottom: 9px;
  padding-top: 9px;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

#getWaitlistContainer dt
{
    margin: 0;
    font-size: 16px;
}

#getWaitlistContainer dd
{
    margin: 0;
    font-size: 40px;
}

#getWaitlistContainer dd span
{
    font-size: 12px;
}

#getWaitlistContainer .gw-text-base {
    font-size: 16px;
}
/*end*/


a:hover {
    color: #015d4a;
}


.button.disabled {
    background: #e3e3e3;
    color: gray;
    cursor: pointer;
}

.btn-sticky-container {
    position: sticky;
    bottom: 0;
    background: #f5f5f7;
    width: 100%;
    z-index: 10;
    margin-top: 40px;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-left: 0;
}

h3.card-heading.sp-card {
    font-weight: 600;
    min-height: 80px;
    margin-bottom: 0;
}

#header .navbar, #header .navbar-signedin {
    padding-top: 0.4svw;
    padding-bottom: 0.4svw;
}

.text-dark-gey {
    color: var(--dark-grey) !important;
}

.slider-container#provider_slider {
    margin-left: -15px;
    padding-left: 15px;
}

.card-heading-smaller.display-inline.bold.new
{
    font-weight: 500 !important;
}

.service-pack-button.consultation-btn.mobile {
    display: none;
}

.services-page-loading {
    position: absolute;
    height: 50vh;
    width: 100%;
}

@media screen and (min-width: 1670px) {
    .w-layout-grid-3 {
        grid-template-columns: 1fr 1fr 1fr;
    }

}

@media screen and (max-width: 479px) {


    .services-page-loading {
        position: relative;
        height: 20vh;
    }

    .service-pack-button.consultation-btn {
        display: none;
    }

    .service-pack-button.consultation-btn.mobile {
        display: block;
        margin: 25px 20px 0px;
    }


    #root>.section {
        margin-left: 0;
        margin-right: 0;
    }
    .dashboard-column-main {
        margin-bottom: 0px;
    }

    .slide-box.margin-right.pro-delivered.service-card.internal .image-div img {
        bottom: 50px;
    }

    h1, .sp-hero-content-info-heading h1 {
        font-weight: 600;
        line-height: 32px;
    }
    h3.card-heading.sp-card {
        min-height: 65px;
    }
    .div-block.small-top-space {
        margin-bottom: 10px;
    }

    .w-layout-grid .slide-box.margin-right.new {
        margin-right: 0;
        width: auto;
    }

    .slide-box.margin-right.new.colour-tint.dark-blue {
        width: auto;
        margin-right: 0;
    }


    .column-2.padding {
        margin-top: 44px;
    }

    .card-cta.overlap.new {
        margin-top: -5px;
        padding-top: 16px;
        border-radius: 0px;
    }

    .slider-container .slide-box.margin-right.new.colour-tint.dark-blue {
        margin-right: 20px;
    }

    .slider-container .slide-box.margin-right.new.colour-tint.dark-blue {
        max-width: 90%;
    }

}

.text-red
{
    color: red !important;
}

.star::after {
    conten: "★";
}
.star.empty::after {
    conten: "☆";
}

.sp-wrapper {
  padding-bottom: 20px;
  background-color: white;
  color: initial;
}

.sp-wrapper p {
  margin-top: 0;
  margin-bottom: 0;
}


.sp-hero {
    background-color: #f5f5f7;
  display: flex;
  justify-content: center;
  padding: 20px 0 70px;
  width: 100%;
}

.sp-hero-inner-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 1540px;
  padding: 0 50px;
  width: 100%;
}

.sp-hero-actions.action-icons img{
    width: 25px;
}

div.mobile-gallery-slider {
    display: none;
}

.sp-hero-actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 70px;
  justify-content: space-between;
  width: 100%;
}

.sp-hero-grouping {
  align-items: center;
  display: flex;
  gap: 15px;
}

.sp-hero-grouping-text {
  align-items: center;
  color: #444;
  display: flex;
  gap: 6px;
  cursor: pointer;
}
.sp-hero-actions.action-icons .sp-hero-grouping-text:first-of-type {
    margin-right: 15px
}

.sp-hero-actions.action-icons .sp-hero-grouping-text.sell-service {
    margin-left: 15px
}


.sp-hero-grouping-text span {
  font-size: 24px;
  font-weight: 300;
}

.sp-hero-grouping p {
  font-size: 17px;
  font-weight: 500;
}

.sp-hero-underline-text {
  text-decoration: underline;
}

.sp-hero-content {
  display: flex;
  align-items: flex-start;
}

.sp-hero-content-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 60px;
}

.sp-hero-content-info h3 {
  font-size: 21px;
  font-weight: 500;
  margin: 15px 0 0;
}

.sp-hero-content-info-heading { 
  align-items: flex-start;
  display: flex;
  margin: 12px 0;
}

.sp-hero-content-info-heading-logo {
  margin-right: 24px;
  width: 120px;
  height: 120px;
  max-width: 120px;
  object-fit: cover;
  border-radius: 50%;
}

.sp-hero-content-info-heading-verified-badge-desktop {
  align-items: center;
  background-color: #a4f76a;
  border-radius: 5px;
  color: #275c4b;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  gap: 5px;
  height: 24px;
  justify-content: center;
  margin-top: -20px;
  position: relative;
  width: 115px;
  z-index: 1;
}

.sp-hero-content-info-heading-verified-badge-mobile {
  display: none;
}


.sp-hero-content-info-heading h1 {
  font-size: 37px;
  font-weight: 600;
  margin: 0;
}

.sp-hero-content-info-heading .sp-hero-content-info-heading-verified-badge-mobile {
    margin-bottom: 10px;
}


.sp-hero-content-info-heading h2 {
  color: #888;
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
}

.service-pack-button {
  all: unset;
  background-color: #275c4b;
  border-radius: 8px;
  color: #9dff58;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  padding: 17px 0;
  text-align: center;
}

.sp-hero-content-info button:hover {
  opacity: 0.9;
}

.sp-hero-content-info button:active {
  opacity: 1;
}

.sp-vertical-divider {
  border-left: 1px solid #ccc;
}

.sp-vertical-divider {
  border-left: 1px solid #ccc;
}

.sp-horizontal-divider {
  border-bottom: 1px solid #ccc;
}

.sp-hero-pack-info {
  display: flex;
  height: 55px;
  justify-content: space-between;
  margin: 40px 0;
  /*max-width: 350px;*/
  
  max-width: 410px;
}

.sp-hero-pack-info .sp-sub-options {
    height: 92px;
}

.sp-hero-pack-info-key {
  color: #888;
  font-size: 14px;
  margin: 0;
}

.sp-hero-pack-info-data {
  font-size: 17px;
  font-weight: 700;
  margin: 14px 0 0;
}

a.sp-hero-pack-info-data {
    text-decoration: underline;    
}

.sp-hero-gallery {
  align-items: flex-start;
  display: flex;
  flex: 2;
  flex-direction: row;
}

.sp-hero-image {
  display: flex;
  margin-right: 16px;
  box-shadow:  -1px 9px 37px #4b49493b;
}

.sp-hero-image img {
  object-fit: contain;
  width: 100%;
}

.sp-hero-image-thumbnails {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 200px;
}

.sp-hero-image-border {
  border-radius: 10px;
  border: 1px solid #ddd;
  overflow: hidden;
}

.sp-hero-left-arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  height: 20px;
  padding: 4px;
  width: 20px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.service-pack-body-wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1540px;
  padding: 60px 50px;
}

.service-pack-body-left {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  max-width: 1000px;
  width: 65%;
}

.service-pack-body-left  .sp-sub-option-body-p-underline.read-more {
    margin: -70px 0 70px 0;
    fontWeight: 400;
}

.clock-number {
    width: 2em;
    text-align: center;
}

.mobile-only {
    display: none !important;
}
.home-page-section-5 {
    margin-top: 0;
    padding-top: 0;
}


@media screen and (max-width: 479px) {
    .home-page-section-5>.container-4._20vw-left-only:first-of-type {
        padding-top: 0;
    }

    .desktop-only {
        display: none !important;
    }

    .mobile-only {
        display: flex !important;
    }
    .clock-number {
        width: 65px;
        text-align: center;
    }

    .sp-wrapper {
        padding-bottom: 30px;
    }

    .service-pack-body-left  .sp-sub-option-body-p-underline.read-more {
        padding-left: 20px;
    }
}

.service-pack-body-right {
  max-width: 380px;
  width: 100%;
}

.sp-description {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
}

.sp-description-title {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.sp-description-heading {
  font-size: 32px;
  font-weight: 500;
}

.sp-description-paragraph {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 80px;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  transition: all 0.3s ease-in-out;
  white-space: pre-line;
}

.sp-description-paragraph.clamped {
    max-height: 284px;
    -webkit-box-orient: vertical;
    transition: all 0.3s ease-in-out;
}

.sp-description-list {
  list-style: none;
  margin-bottom: 100px;
  padding-left: 0;
}

.sp-description-list li:before {
  color: #58b137;
  /*content: url('/green-tick-small.svg');*/
  font-size: 24px;
  margin-right: 18px;
}

.sp-description-list-item {
  display: flex;
  font-size: 17px;
  font-weight: 400;
  line-height: 34px;
  margin-bottom: 15px;
}

.sp-sub-options {
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  padding-bottom: 35px;
  overflow-x: auto;
  width: 100%;
}

.sp-sub-option {
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 303px;
}

.sp-sub-option-type {
  color: white;
  font-weight: 600;
  height: 32px;
  margin-bottom: 10px;
}

.sp-sub-option-type-title {
  background-color: #999;
  display: none;
  justify-content: center;
  margin: 0;
  padding: 7px 0;
}

.sp-show {
  display: flex;
}

.sp-sub-option-body {
  display: flex;
  flex-direction: column;
  padding: 0 19px 19px;
  position: relative;
  height: 100%;
  min-height: 600px;
}

.sp-sub-option-body .service-pack-button {
    bottom: 20px;
    position: absolute;
    width: -fill-available;
    width: -webkit-fill-available;
    width: -moz-available;
    margin-top: 20px;
    margin-right: 20px;
}

.sp-sub-option-body h5 {
  color: #888;
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0;
}

.sp-sub-option-body h1 {
  margin: 7px 0 22px;
}

.sp-sub-option-body p {
  color: #888;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  margin: 7px 0;
}
.sp-sub-option-body p:last-of-type {
  /*margin-bottom: 80px;*/
}
.sp-sub-option-body-p-underline {
  text-decoration: underline;
}

.sp-reviews-title {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sp-reviews-title h4 {
  color: #888;
  font-size: 14px;
  font-weight: 500;
  margin: 24px 0 0;
}

.sp-reviews-title button {
  all: unset;
  border: 1px solid #ddd;
  border-radius: 25px;
  color: #888;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  padding: 13px 45px;
}

.sp-reviews-rating {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  margin: 10px 0 25px;
}

.sp-reviews-rating h1 {
  font-size: 33px;
  margin: 0;
}

.sp-reviews-rating h2 {
  margin: 0;
}

.sp-all-reviews-button {
  all: unset;
  border: 1px solid #323232;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 45px;
}

.sp-reviews-cards {
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  margin-bottom: 25px;
  overflow-x: auto;
  padding-bottom: 35px;
  width: 100%;
}

.sp-reviews-card {
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 20px;
  width: 560px;
  padding-bottom: 90px;
}

.sp-reviews-card p {
  color: #888;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 22px;
}

.sp-reviews-card h3 {
  font-size: 19px;
  font-weight: 500;
  line-height: 30px;
  margin: 0;
}

.sp-reviews-card-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  position: absolute;
  width: 510px;
  bottom: 10px;
}

.sp-reviews-card-footer h4 {
  font-size: 15px;
  font-weight: 600;
}

.sp-logo-grid {
  display: flex;
  flex-wrap: wrap;
}

.sp-logo-grid-cell {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 120px;
  justify-content: center;
  width: 24.5%;
}
.sp-logo-grid-cell.left-align {
  justify-content: start;
}

.sp-logo-grid-cell img {
  width: 80%;
}

.sp-purchase-block {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 1px 1px 15px #ccc;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.sp-purchase-block-chips {
  display: flex;
  gap: 6px;
  overflow-x: auto;
  padding: 0 30px 0 10px;
}

.sp-purchase-block-chips p {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 18px;
  margin-top: 16px;
  margin-bottom: 16px;
  white-space: nowrap;
}

.sp-purchase-block-chip-on {
  color: #58b137;
}

.sp-purchase-block-chips-off {
  background-color: #eee;
  border-radius: 6px;
  color: #666;
}

.sp-purchase-block-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.sp-purchase-block-header h2 {
  font-size: 20px;
  margin: 0;
}

.sp-purchase-block-header h3 {
  font-size: 15px;
  margin: 0;
}

.sp-purchase-block-text {
  color: #888;
  font-size: 17px;
  font-weight: 500;
  margin: 15px 0 35px;
  white-space: pre-line;
}
.sp-purchase-block-text u a{
  color: inherit;
  font-weight: inherit;
}
h3.sp-purchase-block-text {
  line-height: unset;
}

.sp-purchase-block-price {
  background-color: #eee;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: center;
}

.sp-purchase-block-price h1 {
  font-size: 36px;
  font-weight: 700;
  margin: 20px 0 0;
}

.sp-purchase-block-price h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 4px 0 20px;
}

.sp-purchase-block-body {
  display: flex;
  flex-direction: column;
  padding: 5px 30px 15px;
}

.sp-purchase-block-terms {
  color: #888;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.sp-purchase-block-compare {
  color: #58b137;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.sp-timing-block {
  background-color: #f7f7f7;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 25px 30px;
  width: 100%;
}

.sp-timing-block-line {
  display: flex;
  justify-content: space-between;
}

.sp-timing-block-line p {
  color: #888;
  font-size: 17px;
  font-weight: 500;
  margin: 10px 0;
}

.sp-provider-block {
  background-color: #f7f7f7;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.sp-provider-block-heading {
  align-items: center;
  background-color: #e9e9ea;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #265c4b;
  display: flex;
  font-size: 17px;
  font-weight: 600;
  height: 45px;
  padding: 5px 20px;
}
.sp-infospan-flat.availability, .sp-infospan-flat,
span.sp-infospan-flat,
p.sp-infospan-flat{
  padding: 10px 20px;
  border-radius: 10px;
  width: 100%;
}

.sp-provider-block-info {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.sp-provider-block-profile {
  width: 70px;
}

.sp-provider-block-profile img {
  width: 100%;
}

.sp-provider-block-details h3 {
  margin: 3px 0 7px;
}

.sp-provider-block-details h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 16px 0;
  text-decoration: underline;
}

.sp-provider-block-details p {
  color: #5e5e5e;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 17px;
  margin-bottom: 17px;
}

.sp-provider-block-badge {
  align-items: center;
  background-color: #a4f76a;
  border-radius: 5px;
  color: #275c4b;
  display: flex;
  gap: 5px;
  height: 24px;
  justify-content: center;
  width: 115px;
}

.sp-provider-block-badge h6 {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.sp-verification-block {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 25px 30px;
  width: 100%;
}

.sp-verification-block h2 {
  color: #58b137;
  font-size: 20px;
}

.sp-verification-block h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0 15px;
}

.sp-verification-block p {
  margin: 14px 0;
}

.sp-verification-block-line {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.sp-verification-block-line img.green-tick {
  height: 16px;
  width: 16px;
}

.sp-verification-block-title {
  align-items: center;
  display: flex;
  gap: 12px;
}

.sp-verification-block-title p {
  color: #5e5e5e;
  font-size: 16px;
  font-weight: 500;
}

.sp-report-service {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.sp-report-service h1 {
  color: #555;
  font-size: 28px;
}

.sp-report-service p,  .sp-report-service a{
  color: #555;
  font-size: 13px;
  text-decoration: underline;
  font-weight: 400;
}

.sp-service-cover-block {
  background-color: #f7f7f7;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 25px 30px;
  width: 100%;
}

.sp-service-cover-block h1 {
  font-size: 25px;
  font-weight: 300;
  margin: 0;
}

.sp-service-cover-block span {
  color: #58b137;
}

.sp-service-cover-block h3 {
  color: #555;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
}

.sp-service-cover-block h4 {
  color: #555;
  cursor: pointer;
  font-size: 19px;
  font-weight: 500;
  margin: 0 0 14px;
  text-decoration: underline;
}

.sp-service-cover-block a {
  all: unset;
  cursor: pointer;
  text-decoration: underline;
}

.sp-did-you-know-block {
  background-color: #f7f7f7;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  gap: 25px;
  margin-bottom: 20px;
  padding: 25px 30px;
  width: 100%;
}

.sp-did-you-know-block img {
  height: 45px;
  width: 47px;
}

.sp-did-you-know-block-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sp-did-you-know-block h3 {
  color: #000;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}

.sp-did-you-know-block h4 {
  color: #555;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  margin: 10px 0;
}

.service-pack-mobi-footer {
  display: none;
}


.auth-save-button {
    bottom: 0px;
    position: sticky;
    background: rgb(245, 245, 247, 0.75);
    width: 100%;
    z-index: 10;
    margin-top: 40px;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-left: 0;
}

.button-text.approval-pending {
    color: #EE9500;
}


@media only screen and (max-width: 1200px) {
  .sp-hero-content {
    flex-direction: column;
  }

  .sp-hero-content-info {
    margin-bottom: 30px;
    margin-right: 0;
    width: 100%;
  }

  .sp-hero-gallery {
    width: 100%;
  }

  .sp-hero-image {
    width: 100%;
  }

  .service-pack-body-left {
    width: 50%;
  }

  .sp-logo-grid-cell {
    height: 90px;
  }

  .sp-logo-grid-cell img {
    width: 80%;
  }
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
  .sp-hero {
    overflow: hidden;
    padding-bottom: 35px;
  }

  .sp-hero-inner-wrapper {
    padding: 0;
  }

  .sp-hero-actions {
    box-sizing: border-box;
    padding: 0 20px;
  }

  .sp-hero-grouping {
    align-items: baseline;
    gap: 18px;
  }

  .sp-hero-grouping-text {
    font-size: 28px;
    font-weight: 400;
  }

  .sp-hero-grouping p {
    display: none;
  }

  .sp-hero-content-info {
    box-sizing: border-box;
    padding: 0 20px;
  }

  .sp-hero-content-info-heading-logo {
    width: 75px;
    height: 75px;
  }

  .sp-hero-content-info-heading-verified-badge-desktop {
    display: none;
  }

  .sp-hero-content-info-heading-verified-badge-mobile {
    align-items: center;
    background-color: #a4f76a;
    border-radius: 5px;
    color: #275c4b;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    gap: 5px;
    height: 24px;
    justify-content: center;
    position: relative;
    width: 85px;
    z-index: 1;
    padding: 3px 0;
  }

  .sp-hero-content-info-heading-verified-badge-mobile.pro-delivered {
      width: 115px;
  }

  .sp-hero-content-info-heading h1 {
    font-size: 28px;
  }

  .sp-hero-content-info-heading h2 {
    font-size: 14px;
    margin: 5px 0 15px;
  }

  .sp-hero-content-info h3 {
    font-size: 20px;
  }

  .sp-hero-pack-info {
      margin: 24px 0 0;
      max-width: unset;
      height: auto;
  }

  .sp-hero-pack-info .sp-sub-options {
      height: auto;
      padding-left: 0;
      padding-bottom: 0;
  }


  .sp-hero-pack-info {
      overflow-y: hidden;
      padding-bottom: 10px;
  }

  .sp-hero-pack-info .info-block {
      width: auto;
      white-space: nowrap;
  }

  .sp-hero-pack-info .info-block .sp-hero-pack-info-key {
      white-space: nowrap;
  }

  .sp-hero-pack-info .info-block .sp-hero-pack-info-data.mobile {
      max-width: 300px;
      display: block;
      margin: 0;
      line-height: 27px;
      overflow: clip;
      text-overflow: ellipsis;
  }



  .sp-hero-content-info button {
    display: none;
  }

  .sp-hero-gallery {
    box-sizing: border-box;
    overflow-x: auto;
    padding: 0;
  }
  .sp-hero-gallery .sp-sub-options {
      padding:  0 20px 10px 20px;
      margin-left: 0;
  }

  .sp-hero-image {
    display: none;
  }

  .sp-hero-image-thumbnails {
    display: none;
    flex-direction: row;
    max-width: none;
  }

  .sp-hero-thumbnail {
    display: flex;
    width: 300px;
  }

  .service-pack-body-left {
    width: 100%;
  }

  .service-pack-body-wrapper {
    padding: 0;
  }

  .sp-mobi-padding {
    padding: 0 20px;
  }

  .service-pack-body-wrapper {
    flex-direction: column;
    margin-top: 30px;
  }

  .sp-description h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .sp-description-paragraph {
    font-size: 16px;
    line-height: 28px;
  }


  .sp-description-list li:before {
    font-size: 20px;
    margin-right: 10px;
  }

  .sp-description-list-item {
    font-size: 16px;
    line-height: 28px;
  }

  .sp-sub-options {
    padding: 0 20px 35px;
  }

  .sp-sub-option {
    width: 280px;
  }

  .sp-sub-option-type {
    height: 26px;
    margin-bottom: 6px;
  }

  .sp-sub-option-type-title {
    font-size: 12px;
  }

  .sp-sub-option-body {
    padding: 0 15px 15px;
  }

  .sp-sub-option-body h5 {
    font-size: 13px;
  }

  .sp-sub-option-body h1 {
    font-size: 24px;
  }

  .sp-sub-option-body p {
    color: #555;
    font-size: 15px;
    margin: 4px 0;
    line-height: 22px;
  }

  .sp-sub-option-body button {
    border-radius: 5px;
    font-size: 16px;
    padding: 10px 0;
  }

  .sp-reviews-title {
    margin-bottom: 25px;
  }

  .sp-reviews-title button {
    font-size: 16px;
    padding: 6px 16px;
  }

  .sp-reviews-rating h2 {
    font-size: 17px;
  }

  .sp-reviews-cards {
    padding: 0 20px 15px;
  }

  .sp-reviews-card {
    padding: 4px 18px;
    width: 90%;
  }

  .sp-reviews-card p {
    font-size: 14px;
  }

  .sp-reviews-card h3 {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 55px;
  }
  .sp-reviews-card-footer {
    font-size: 14px;
  }

  .sp-all-reviews-button {
    border-radius: 6px;
    box-sizing: border-box;
    padding: 12px 45px;
    text-align: center;
    width: 100%;
  }

  .sp-logo-grid-cell {
    height: 90px;
    width: 33%;
  }

  .sp-logo-grid-cell img {
    width: 70%;
  }

  .service-pack-body-right {
    box-sizing: border-box;
    /*margin-bottom: 100px;*/
    max-width: none;
    padding: 0 20px;
  }

  .sp-purchase-block {
    display: none;
  }

  .sp-service-cover-block {
    padding: 20px 30px;
  }

  .sp-service-cover-block h1 {
    font-size: 21px;
  }

  .sp-service-cover-block h3 {
    font-size: 17px;
    line-height: 24px;
  }

  .sp-service-cover-block h4 {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .sp-did-you-know-block {
    padding: 20px 30px;
  }

  .sp-did-you-know-block h3 {
    font-size: 17px;
    line-height: 24px;
  }

  .service-pack-body-left {
    margin-right: 0;
  }

  .service-pack-mobi-footer {
    box-sizing: border-box;
    background-color: white;
    border-top: 1px solid #eee;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 14px 20px;
    position: sticky;
    width: 100%;
    z-index: 10;
  }

  .service-pack-mobi-footer.auth-footer {
      bottom: 65px;
  }

  .auth-save-button {
      bottom: 65px;
      position: sticky;
      background: rgb(245, 245, 247, 0.75);
      width: 100%;
      z-index: 10;
      margin-top: 0px;
      padding-top: 10px;
      padding-bottom: 20px;
      margin-left: 0;
  }

  .service-pack-mobi-footer p {
      color: #2e2e2e;
  }

  .service-pack-mobi-footer-left h3 {
    margin: 0 0 5px;
  }

  .service-pack-mobi-footer-left p {
    font-size: 12px;
    margin: 0;
  }

  .service-pack-mobi-footer .service-pack-button {
    border-radius: 6px;
    padding: 0px 24px;
    font-size: 16px;
    height: 45px;
  }
}

.modal {
    display: none;
}

.main-modal.bg-white .modal-scroll {
  background-color: var(--white);
}

.modal-2-popup {
    background-color: rgba(0, 0, 0, 0.7);
    padding-top: 0;
}


.modal .modal-2-popup {
    z-index: 1000;
}
.modal.show {
    display: block;
    z-index: 1001
}
.modal .modal-w-scroll {
    width: auto;
}
.modal .modal-scroll {
    padding: 30px;
}

.modal:not(.lightbox-modal) .modal-2-popup {
    padding-top: 50px;
    padding-bottom: 50px;
}


.modal:not(.lightbox-modal) .modal-w-scroll {
    max-width: 650px;
}


.modal.large-layout-modal .modal-2-popup {
  padding-left: 15vw;
  padding-right: 15vw;
}

.modal.large-layout-modal .modal-w-scroll {
  max-width: unset;
  width: 100%;
}


.modal.ask-specialist-modal .modal-w-scroll {
    max-width: unset;
}

.modal.ask-specialist-modal .ask-specialist-modal-header {
    margin: -30px -28px 0px;
    border-top-right-radius: 0.75rem;
    border-top-left-radius: 0.75rem;
    padding: 25px 35px 0px;
}

.modal.ask-specialist-modal .ask-specialist-modal-header h2 {
    padding-bottom: 25px;
    max-width: 50%;
    margin: auto 60px;
    font-weight: bold;
    font-size: 38px;
}
.modal.ask-specialist-modal .ask-specialist-modal-header img {
    width: 300px;
}

.modal.ask-specialist-modal form h3 {
    margin-bottom: 0;
    margin-right: 10px;
}

.lightbox-modal.modal .modal-scroll, .lightbox-modal.modal .modal-scroll-content-w {
    overflow: visible;
    padding: 0px;
    border-radius: 0;
    background-color: unset;
}

.lightbox-modal.modal .dismiss-notification-cross {
    opacity: 1;
}

.login-modal.modal .modal-scroll {
    padding: 60px;
}


.modal .modal-w-scroll .dismiss-icon  {
    position: absolute;
    top: 0;
    right: 0;
}
.modal .modal-w-scroll .dismiss-icon .dismiss-notification-cross {
    z-index: 1050;
    position: fixed;
    margin-left: -45px;
    margin-top: 15px;
    top: unset;
    right: unset;
}


.slider-container#similar_slider, .slider-container#provider_slider{
    padding-top: 50px;
}

.slider-container.slider-box-margin
{
    margin-left: -15px;
    padding-left: 15px;
}



.sp-description-list-item img.green-tick {
    scale: 40%;
    position: absolute;
    left:-8px;
    top: 1px;
}

@media screen and (max-width: 479px) {

    .container.panel.image-background {
        background-position: 45% 121%;
        background-size: 611px;
    }

    .modal.ask-specialist-modal form {
        margin-bottom: 100px;
    }

    .modal.ask-specialist-modal .ask-specialist-modal-header h2 {
        text-align: center;
        padding: 35px;
        padding-left: 10px;
        padding-right: 10px;
        max-width: unset;
        margin: auto;
        font-size: 30px;
    }
    .modal.ask-specialist-modal .ask-specialist-modal-header img {
        margin: auto;
        width: unset;
    }

    .modal.ask-specialist-modal .ask-specialist-modal-header {
        flex-direction: column;
        max-width: 100vw;
    }

    .modal.ask-specialist-modal .multiselect-container .chip.singleChip {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 260px;
    }
    .support-form .multiselect-container .chip.singleChip {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 220px;
    }


    .modal:not(.lightbox-modal) .modal-2-popup {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .hero-section-waitlist {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .large-box {
        width: 100%;
    }

    .dash-main-content {
        margin-bottom: 80px;
    }

    .dash-main-content .container-metrics{
        padding-left: 20px;
        padding-right: 20px
    }

    .dash-main-content .panel-text-container {
        border-radius: 0;
    }

    .dash-main-content>.section-divider, .section-divider.no-top-space.padding {
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    .ratings-section, .rating-val {
        justify-content: end;
        display: flex;
    }

    .sp-reviews-card-footer {
        width: 90%;
        margin: 20px 0 10px 0;
    }
    .sp-reviews-card-footer h4 {
        margin: 0;
    }


    .sp-description-list-item img.green-tick {
        top: -2px;
    }

    .slider-container
    {
        padding-right: 20px;
        margin-left: -15px;
        padding-left: 35px;
    }

    .for-you-more-providers {
        margin-left: -15px;
        padding-left: 30px;
    }

    .slider-container#similar_slider, .slider-container#provider_slider{
        padding-top: 20px;
    }


    .sp-sub-option-body {
        min-height: 500px;
    }

    .login-modal.modal .modal-scroll {
        padding: 20px;
    }

}

.mobile-service-filter {
    display: none;
}

.panel-body.gray.waitlist {
    height: fit-content !important;
}

.panel-height-large-new .slide-box.margin-right.new, .panel-height-large .slide-box.margin-right.new,
.draggable-area:not(.categories-slider):not(.more-provider-slider) .slide-box.margin-right.new{
    height: 100%;
    max-height: 590px;
    min-height: 590px;
}

.service-pack-body-wrapper.more-services {
    padding-top: 50px;
}


.task-row.title p.spots-left {
    top: 0;
    right: 0;
}

@media screen and (max-width: 479px) {

    .task-row.title p.spots-left {
        top: -50px;
    }

    .section.small-top-space.wf-section.services-section {
        padding-left: 10px;
        padding-right: 10px;
    }

    .multiselect-container.form-input.form-input-large {
        padding-left: 0px;
    }


    ._20vw-div {
        width: 5px;
    }

    .slider-container .slide-box.margin-right.pro-delivered.w-inline-block.service-card, .slider-container .slide-box.margin-right.pro-verified.w-inline-block.service-card,
    .slider-container .draggable-area:not(.categories-slider):not(.more-provider-slider) .slide-box.margin-right.new {
        max-height: 555px;
        min-height: 555px;
    }
    .slide-box.margin-right.pro-delivered.w-inline-block.service-card, .slide-box.margin-right.pro-verified.w-inline-block.service-card,
    .draggable-area:not(.categories-slider):not(.more-provider-slider) .slide-box.margin-right.new {
        max-height: 560px;
        min-height: 560px;
    }

    .service-pack-body-wrapper.more-services {
        padding-top: 0;
    }

    .checkbox-switch {
        scale: 1.2;
    }

    .service-filter {
        display: none;
    }
    .mobile-service-filter.show {
        display: block;
        position: sticky;
        width: 100%;
        height: 100vh;
        z-index: 1001;
    }

    .mobile-service-filter .text-smallish {
        font-size: 17px;
    }

    .modal .modal-scroll {
        padding: 25px;
    }

    .modal .modal-scroll {
        padding: 20px;
        position: absolute;
        left: 0;
    }
    .lightbox-modal.modal .modal-scroll {
        padding: 0;
        position: relative;
    }
    .lightbox-modal.modal .modal-scroll {
        padding: 0;
        position: relative;
        overflow: visible;
    }


    .mobile-service-filter .modal-2-popup {
        top: 0px;
        overflow-y: auto;
    }
    .mobile-service-filter .modal-2-popup .modal-w-scroll {
        margin-top: 25px;
        height: 100vh;
        border-radius: 0;
    }
    .mobile-service-filter .modal-2-popup .modal-scroll {
        max-height: calc(100% - 30px);
        max-width: 100vw;
    }
    .mobile-service-filter .modal-2-popup .modal-scroll .modal-buttons {
        width: 100%;
        left: 0;
        position: absolute;
        bottom: 0;
        padding: 20px;
    }


    .mobile-service-filter .modal-2-popup .modal-scroll .modal-buttons .fake-buy-button:first-of-type {
        margin-right: 15px;
        cursor: pointer;
    }

    .mobile-service-filter .modal-2-popup .modal-scroll .modal-buttons .fake-buy-button:last-of-type {
        margin-left: 15px;
        cursor: pointer;
    }


    .mobile-service-filter .modal-2-popup .modal-scroll .modal-buttons .fake-buy-button.alt {
        background-color: #C4F0BB;
     
    }
    .mobile-service-filter .modal-2-popup .modal-scroll .modal-buttons .fake-buy-button.alt  .button-text{
        color: #035d4a !important;
    }


    .ask-a-specialist {
        display: none;
    }
    .ask-a-specialist.mobile {
        display: inherit;
        margin-bottom: 40px;
    }

    .ask-a-specialist.mobile .panel-body.no-padding.top-space {
        padding-top: 20px;
    }

    .ask-a-specialist.mobile .person-container{
        margin-bottom: 0;
    }


    .panel-height-large-new .slide-box.margin-right.new, .panel-height-large .slide-box.margin-right.new {
        height: 100%;
        max-height: 565px;
        min-height: 565px;
    }

}

.sp-info-panel {

}
.sp-info-panel-line {
  margin-bottom: 45px;
}
.sp-info-panel-line p {
  color: #888;
  font-size: 17px;
  font-weight: 500;
}
.sp-info-panel-line .specialities {
  display: flex;
  flex-wrap: wrap;
}
.sp-info-panel-line .specialities p {
  font-size: 17px;
  background-color: #dafacd;
  color: #5d9932;
  border-radius: 4px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0 7px 0 7px;
  whitespace: nowrap;
}
.sp-info-panel-line .specialities p:last-of-type {
  margin-right: 0px;
}

.button.w-tab-link {
  margin-right: 0;
  box-shadow: none;
  color: #2e2e2e;
  background-color: #eee;
}
.button.w-tab-link.w--current {
  color: #6ef040;
  background-color: #015d4a;
  font-weight: 500;
}

.availability.switch-field {
  color: #2e2e2e;
  background-color: #6ef040;
}

.partner-panel .hero-content.space {
    margin-bottom: 59px;
}

.panel-body.accordion .accordion-head{
    width: 100%;
}

.panel-body.accordion .icon-circle-small {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    transform-style: preserve-3d;
    will-change: transform;
}
.panel-body.accordion.show .icon-circle-small {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(90deg) skew(0deg);
    transform-style: preserve-3d;
    will-change: transform;
}

.accordion-body {
    display: none;
}

.panel-body.accordion.show .accordion-body {
    display: block;
    opacity: 1;
}
.pointer {
  cursor: pointer !important;
}
.display-none {
  display: none !important;
}
.justify-content-center {
  justify-content: center !important;
}

.multiselect-container {
    border: 1px solid #c9ccd4;
    min-height: 45px;
    border-radius: 8px;
    align-items: center;
    display: grid;
}

.multiselect-container.multiSelectContainer li:hover {
    background: var(--primary-1);
}

.multiselect-container .optionListContainer {
    top: 45px;
}

.multiselect-container .chip.singleChip {
    color: #242431 !important;
    font-size: inherit;
    margin-bottom: 0px;
}

.multiselect-container .chip.false:not(.singleChip) {
    background-color: #015d4a;
}
.multiselect-container .chip.singleChip img.closeIcon {
    background-color: #b4b4b4;
    border-radius: 9px;
    height: 15px;
    width: 15px;
}

.multiselect-container .chip img.closeIcon {
    margin-right: 10px;
}
.settings-page .multiselect-container .searchWrapper input {
    margin: 0;
}
.settings-page .multiselect-container .searchWrapper {
    border: 1px solid #ccc !important;
    outline: none !important;
    border-radius: 8px;
    height: 45px;
    display: flex;
    flex-wrap: wrap;
}
.settings-page .multiselect-container input {
  position:absolute;
  height: 100%;
  top: 0;
}
.settings-page .multiselect-container .chip.false:not(.singleChip) {
  display: flex;
  top: 50px;
  position: relative;
}

.multiselect-container .searchWrapper {
    border: none !important;
    outline: none !important;
}

.fake-single-select .multiselect-container .chip.false:not(.singleChip) {
    color: #242431 !important;
    font-size: inherit;
    margin-bottom: 0px;
    background: transparent;
}
.fake-single-select .multiselect-container .chip img.closeIcon {
    background-color: #b4b4b4;
    border-radius: 9px;
    height: 15px;
    width: 15px;
}

.searchWrapper input {
    border: none !important;
    outline: none !important;
    width: 100%;
    height: unset;
}

.multiselect-container .searchWrapper input {
    pointer-events: none;
}

.border-radius-50 {
    border-radius: 50% !important;
}
.p-0 {
  padding: 0 !important;
}
.m-0 {
  margin: 0 !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mt-0 {
    margin-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
    text-align: right !important;
}
.text-left {
    text-align: left !important;
}

.flex-direction-col {
    flex-direction: column !important;
}
.flex-direction-row {
    flex-direction: row !important;
}

label {
    color: #5f5f5f;
}

.animate-label {
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 17px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.section .form-vertical {
    grid-row-gap: 2px;
}

.form-vertical .input-group {
    position: relative;
    margin-bottom: 25px;
}


.form-vertical .input-group input:focus ~ label,
.form-vertical .input-group input:valid ~ label {
    top: -20px;
    left: 0px;
    font-size: 14px;
    color: #5f5f5f;
}


.admin-service-completed {
  margin-top: 20px;
  justify-content: center;
  width: 75%;
  text-align: center;
  border-radius: 8px;
  padding: 17px;
  color: #121412;
  background-color: #73e473c4;
  flex-wrap: wrap;
}
.admin-service-compelted p {

}

.expandable {
    /*background: #fff;*/
    overflow: hidden;
    /*color: #000;   */
    /*line-height: 50px;*/

    transition: all .5s ease-in-out;
    height: 0;
}

.expandable.expanded {
    height: auto;
    overflow: visible;
/*    padding-left: 18px;
    padding-bottom: 5px;
/*    border-left-style: inset;*/
    margin-top: 0px;
/*    border-left-color: #d1d1d12b;*/
}
.expandable.expanded h3 {
    margin-bottom: 8px;
    /*margin-top: 5px;*/
}

#register-form .tabs-vertical-switch.w--current {
    background-color: #015d4a;
}

h5.blog-small-heading.green {
    position: relative;
}

h5.blog-small-heading.green:after { 
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    height: 8px;
    width: 100%;
    border: solid 2px #1db100;
    border-color: #1db100 transparent transparent transparent;
    border-radius: 35%;
    rotate: -1deg;
}

.no-scroll {
    overflow: hidden !important;
}
.no-z-index {
    z-index: 0 !important;
}

.nav-hidden {
    display: none !important;
}
.hidden {
    display: none !important;
}

.section .dashboard-column-1.extra-space.bg-white {
    top: 56px;
    padding-top: 20px;
    height: calc(100vh - 56px);
}

.section .dashboard-column-1.extra-space.bg-white {
    padding-left: 0;
}


.service-deliverables-modal.modal  .modal-2-popup {
    position: absolute;
    width: 100vw;
}

.service-deliverables-modal .dismiss-notification-cross {
    top: 0;
    right: 0;
}
.lightbox-modal .modal-2-popup {
    background-color: rgba(0, 0, 0, 0.9);
}

@media screen and (max-width: 767px) {

    .mobilescreenhero {
        max-width: 100vw;
    }

    .lightbox-modal .modal-w-scroll {
        margin-bottom: auto;
    }
}

.lightbox-modal .ctrl-button img {
    /*border-radius: 50%;*/
    /*padding: 5px;*/
    width: 100%;
    height: auto;
}
.lightbox-modal .ctrl-button img:hover {
    opacity: 1;
}
.lightbox-modal .ctrl-button.next {
    top: 50vh;
    right: 20px;
    position: fixed;
    width: 60px;
    height: 60px;
}
.lightbox-modal .ctrl-button.prev {
    top: 50vh;
    left: 20px;
    position: fixed;
    width: 60px;
    height: 60px;
}

.lightbox-modal.show .modal-2-popup {
    padding: 0 12vw;
}

.lightbox-modal.show .modal-2-popup.gallery .modal-scroll {
    background-color: unset;
}

.lightbox-modal.show .modal-2-popup.gallery .container-fluid {
    max-width: unset;
}

.lightbox-modal.show .modal-2-popup.gallery .dismiss-notification-cross {
    opacity: 1;
    top: 20px;
    right: 20px;
    width: 35px;
}
.lightbox-modal.show .modal-2-popup video {
    max-width: 100%;
}

@media screen and (max-width: 479px) {


    .lightbox-modal.show .modal-2-popup {
        padding: 0;
    }

    .lightbox-modal.show .modal-2-popup.gallery {
        padding: 0;
        width: 100vw;
    }
    .lightbox-modal.show .modal-2-popup.gallery video {
        max-width: 100vw;
    }

    .lightbox-modal.show .modal-2-popup.gallery .dismiss-notification-cross {
        opacity: 1;
        top: 20px;
        right: 20px;
        width: 25px;
    }

    .lightbox-modal .modal-w-scroll {
        margin-top: auto;
    }
    .lightbox-modal .ctrl-button.next {
        top: 70vh;
        right: 5px;
    }
    .lightbox-modal .ctrl-button.prev {
        top: 70vh;
        left: 5px;
    }
    .lightbox-modal .ctrl-button img {
        opacity: 1;
    }

    .service-deliverables-modal .dismiss-notification-cross {
        top: 10px;
        right: 10px;
    }

    .section.hero-section.no-bottom-space .manyhands-carousel .w-slide {
        background-image: none !important;
    }

}

.gallery-view-more {
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    top: 0;
    left: 0;
}
.gallery-view-more span {
    margin: auto;
    padding: 5px 15px;
    background: white;
    border-radius: 16px;
}

.service-packs-empty h5  {
    font-size: 16px !important;
}
.service-packs-empty,
.service-packs-empty h5,
.service-packs-empty div,
.service-packs-empty p {
    font-size: 16px !important;
}

.disable-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#provider_slider.slider-container #w-slider-mask-0::-webkit-scrollbar,
#similar_slider.slider-container #w-slider-mask-0::-webkit-scrollbar{
    display: none;
}

#provider_slider.slider-container #w-slider-mask-0,
#similar_slider.slider-container #w-slider-mask-0 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.div-block-6.filter-container {
    top: 44px;
    background: #f5f5f5;
    display: none;
    justify-content: flex-start;
    padding: 10px 10px;

}
.div-block-6.filter-container a img {
    margin-right: 10px;
}

.div-block-6.filter-container a{
    border: 1px solid #9e9e9e;
    color: #2e2e2e;
    background-color: white;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
}
.div-block-6.filter-container a.clear-filter{
    color: #6d6c6c;
    background-color: transparent;
    border: none;
}


.optionListContainer.displayBlock {
    z-index: 6;
}

.image-uploader {
  background-color: lightgray;
  border: 2px gray dashed;
  height: 350px;
  max-width: 730px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-uploader:hover button.button {
    color: #015d4a;
    background-color: #6ef040;
}

.image-uploader input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.image-uploader p {
  width: 100%;
  position: absolute;
  align-content: center;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 130px;
}

output#list {
  display: flex;
}

output#list span {
  position: relative;
  height: 100px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
output#list span i {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    margin: auto;
    /* width: 90%; */
    /* height: 75px; */
    /* background-color: #00000054; */
    top: 85px;
    border: 1px solid white;
    display: flex;
}
output#list span:hover i {
  opacity: 1;

}
output#list span i::before {
  margin: auto;
  /* color: white; */
}

.wishlist-button:active {
    filter: blur(0px) brightness(0%) !important;
    outline: none !important;
}

.fake-buy-button.pre-order {
    background-color: #2e2e2e;
}
.fake-buy-button.pre-order .button-text{
    color: #fff;
}

.panel-height-large-new .slide-box.margin-right,
.panel-height-large .slide-box.margin-right {
    margin-right: 0;
}

.slide-box.margin-right .card-cta.overlap.new {
    padding-bottom: 5px;
}
.slide-box.margin-right .card-cta.overlap.new.standard-display {
    padding-bottom: 5px;
    margin-top: -5px;
}

.slide-box.margin-right .card-cta.overlap.new.service-pro-info-card {
    padding-bottom: 26px;
}

.slide-box.margin-right.pro-delivered .image-div img,
.slide-box.margin-right.pro-delivered.internal .image-div img {
    position: absolute;
    bottom: 20px;
}

.slide-box.margin-right.pro-delivered.internal .image-div img {
    bottom: 35px;
}

.slide-box.margin-right.pro-delivered .div-block-36,
.slide-box.margin-right.pro-delivered .div-block-36 {
    padding-top: 16px;
    position: absolute;
    width: 100%;
    bottom: 23px;
}



.button-text.redtext {
    color:  #d61616;
    font-weight: 500;
}

.fake-buy-button.outlinebutton.red {
    border-color: #d61616;
}


.fake-buy-button.outlinebutton.red:hover {
    border-style: solid;
    border-color: #d61616;
    background-color: #ffbfbf40;
}

.verified-text.no-bottom-space.ark-green.light-green {
    color: #1eb100;
}

.draggable-area.grabbing :not(a, button) {
    pointer-events: none;
}

.draggable-area.grabbing .clickable {
    pointer-events: auto;
}

#create-tiers-container .draggable-area.grabbing :not(a, button) {
    pointer-events: unset;
}

#create-tiers-container .draggable-area a,
#create-tiers-container .draggable-area button {
    cursor: pointer;
}

.tier-tag {
    position: absolute;
    top: 30px;
    background: #eee;
    color: black;
    padding: 5px;
    max-height: 30px;
    overflow: hidden;
    width: 30px;
    left: -22px;
    align-content: center;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.2) -3px 0px 8px 0px;
    transition: all 0.25s;
}
.tier-tag i {
    border: 1px solid #80808036;
    padding: 0 3px 0 3px;
    cursor: pointer;
    margin: 2px 0 2px 0;
}

.tier-tag:hover {
    max-height: 100px
}


iframe html body .container-fluid .form-horizontal {
    color: #015d4a;
}

.form-vertical-dropdown .div-block-4 {
    padding-right: 0;
    border-radius: 8px;
    box-shadow: none;
}


.menu-overlay {
    background: rgba(0, 0, 0, .55);
    position: fixed;
    z-index: 1000;
    top: 0;
    height: 100vh;
}

.notification-wrapper.onboard {
    position: fixed;
    z-index: 1010;
    background-color: unset;
}

.section.small-bottom-space.smaller-top-space.onboard {
    z-index: 1010;
    position: relative
}


.notification-wrapper.onboard .modal-2-popup .modal-w-scroll .card-body.notification-card-body {
    padding-bottom: 80px;
}

.onboard-container-margin {
    margin-top: 0px

}

.large-panel .content.row {
    margin-top: 30px;
}


.image-uploader p {
    white-space: pre-wrap;
    padding-right: 20px;
    padding-left: 20px;
}

.top-index {
    z-index: 1050;
}

.bg-white-overlay {
    background-color: rgba(255, 255, 255, 0.55) !important;
}

.sell-service-modal .modal-2-popup {
    height: 102vh;
}

.sell-service-modal .modal-w-scroll {
    height: auto;
    width: 55%;
}

.manage-service-button {
    max-width: 250px;
    position: absolute;
    right: 0;
    top: 0
}

.opacity-fade {
    transition: opacity 1.5s ease 1.5s, transform 1.5s ease 1.5s
}
.is-visible {
    transition: opacity 1.5s;
    
    opacity: 1 !important;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg) !important;
    visibility: visible;
}

.verified-panel.light-green.sp-provider-block-badge, .verified-panel.dark-green.sp-provider-block-badge {
    background-color: #fff;
}

.verified-panel.light-green, .verified-panel.dark-green, .verified-panel.dark {
    background-color: transparent;
}

.verified-panel.dark {
    color: #2e2e2e;
}
.verified-panel.dark {
    background-color: var(--white);
    border: 1px solid #2e2e2e;
    border-radius: 6px;
    margin-right: 10px;
    padding-left: 4px;
    padding-right: 10px;
}
.verified-text.no-bottom-space.dark {
    color: #2e2e2e;
    margin-left: 0;
    font-weight: 500;
    line-height: 18px;
}


.sp-hero-actions.action-icons.mobile {
    display: none;
}

.react-date-picker__wrapper {
    border: none;
}

.article-container.updates-version p {
    font-size: 18px;
}

@media screen and (max-width: 479px) {


    a.button.manage-service-button {
        display: none !important;
    }
    a.button.manage-service-button.manage-service-button-mobile {
        margin: 0 20px;
        display: flex !important;
        position: relative;
        max-width: 100%;
    }

    .verified-panel.dark {
        margin-right: 5px;
        padding: 3px 7px 3px 3px;
    }


    .static-nav-block nav .dropdown-link-2{
        margin-bottom: 4px;
    }
    .div-block-6.filter-container {
        display: flex;
        margin-left: 10px;
    }

    .menu-nav-block  .dropdown-link-2.second {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .experts-list-container > .row {
        max-width: 100%;
        margin: auto;
    }
    .experts-list-container > .row > .row {
        max-width: 100%;
        margin: auto;
    }

    .experts-list-container .service-pro-card {
        padding-left: 0;
        padding-right: 0;
    }


    .sp-hero {
        padding-top: 0px;
    }

    .sp-hero-actions.action-icons {
        display: none;
    }
    .sp-hero-actions.action-icons.mobile {
        display: flex;
        height: 45px;
        position: sticky;
        top: 0;
        background-color: #f5f5f7;
        z-index: 1000;
    }


    .sp-hero-actions.action-icons img{
        width: 28px;
    }

    div.mobile-gallery-slider {
        display: flex;
    }


    .sp-logo-grid-cell img {
        width: 100%;
    }

    .sp-hero-content-info-heading .sp-hero-content-info-heading-verified-badge-mobile {
        margin-bottom: 20px;
        display:flex;
    }

    .sp-hero-content-info-heading h2 {
        line-height: 26px;
    }

    .section.small-bottom-space {
        /* margin-left: 0; */
        /* margin-right: 0; */
    }
    .section.small-bottom-space.no-top-space, .section.small-bottom-space.small-top-space, .section.small-bottom-space.smaller-top-space {
        margin-top: 30px;
    }
    /* .section.small-top-space.wf-section { */
    /*     margin-left: 20px; */
    /*     margin-right: 20px; */
    /* } */



    .manage-service2091
    -button {
        max-width: 250px;
        position: relative;
        margin: 30px 0 40px 0;
    }

    .sell-service-modal .modal-2-popup {
        height: 110vh;
        margin-top: -10vh;
        margin-left: -10px;
        width: 100vw;
    }

    .sell-service-modal .modal-w-scroll {
        height: 80vh;
        width: 100%;
        position: absolute;
        bottom: 0;
    }

    .service-deliverables-modal.modal   .modal-2-popup {
        position: absolute;
        width: 100vw;
        /*margin-left: -10px;*/
    }

    .slide-box.margin-right.new.no-bottom-space.more-providers {
        max-width: 91%;
        width: 91%;
    }

    .slide-box.margin-right.new.no-bottom-space.more-providers .profile-image {
        margin: auto;
    }
 

    .large-panel  .div-block-53.padding {
        margin-right: 0;
    }

    .large-panel  .div-block-53.padding .slide-box.margin-right.new {
        width: 100%;
    }

    #header {
        display: none;
    }

    .large-panel .content.row {
        margin-top: 0px;
    }


    .large-panel .content.row>div {
        margin-bottom: 20px;
    }


    .onboard-container-margin {
        margin-top: 10px;
    }

    .image-uploader button {
        top: 60px;
    }


    .image-uploader {
        height: 240px;
    }

    .image-uploader p {
        line-height: 20px;
        white-space: pre-wrap;
        padding-right: 20px;
        padding-left: 20px;
    }

    .navigation-logged-in {
          padding-bottom: 45px;
    }

    .nav-menu-2 {
        position: fixed;
    }

    .menu-overlay {
        z-index: 1010;
        top: 0;
        height: 100vh;
        position: fixed;
    }

    .menu-overlay.mobile {
        z-index: 1001;
        top: 0;
        height: 100vh;
    }

    .notification-wrapper.onboard {
        position: fixed;
        z-index: 1010;
        background-color: unset;
    }

    .notification-wrapper.onboard .modal-2-popup {
        position: fixed;
        z-index: 1010;
        background-color: unset;
    }

    .notification-wrapper.onboard .modal-2-popup .modal-w-scroll {
        bottom: 0;
        position: absolute;
    }

    .notification-wrapper.onboard .modal-2-popup .modal-w-scroll .card-body.notification-card-body {
        padding-bottom: 0;
    }

    .section.small-bottom-space.smaller-top-space.onboard {
        position: fixed;
        z-index: 1010;
    }


    .keyword {
        display: flex;
        flex-direction: row;
    }

    .keyword-text {
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .section.small-bottom-space.no-top-space, .section.small-bottom-space.small-top-space, .section.small-bottom-space.smaller-top-space {
        /*margin-top: 20px;*/
    }

    .nav-menu-2 {
        overflow-y: auto;
        max-height: calc(100vh - 30px);
    }

    .container.grid-container {
        padding-left: 0;
        padding-right: 0;
    }

    .services-column.padding {
        margin-top: 0px;
    }

}

.keyword-tag input:checked {
    color: var(--primary-2) !important;
}

.star-rating {
    width: 15px;
    display: inline-block;
}

.sp-reviews-rating .star-rating {
    width: 27px;
}

.grecaptcha-badge {
    visibility: hidden;
    left: 0;
}

#aichatbot.authenticated #aichatbot-widget-button {
    bottom: 74px;
}

.dante-skeleton {
    display: none !important;
}

.multiselect-container.service_capacity li.option {
    color: var(--dark-grey);
}


#show-hide-pass {
  top: 20px;
  right: 10px;
  opacity: 0.7;
}
